.secured-fields {
    position: relative;
    font-family: RobotoItalic;
    /* font-size: 14px; */
    /*padding: 24px;*/
}

.pm-image {
    background-color: #ffffff;
    border-radius: 4px;
    -moz-boder-radius: 4px;
    -webkit-border-radius: 4px;
    float: right;
    line-height: 0;
    position: relative;
    overflow: hidden;
}

.pm-form-label {
    float: left;
    margin-bottom: 1.2em;
    position: relative;
    width: 100%;
}

.pm-form-label--exp-date {
    width: 48%;
}

.pm-form-label--exp-year {
    width: 48%;
    margin-left: 20px;
}

.pm-form-label--cvc {
    float: right;
    width: 48%;
}

.pm-form-label__text {
    color: #ffffff;
    float: left;
    font-size: 14px;
    padding-bottom: 6px;
    position: relative;
    font-family: LatoRegular;
}

.pm-input-field {
    background: white;
    border: 1px solid #9b9b9b;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 20px;
    box-sizing: border-box;
    clear: left;
    font-size: 18px;
    float: left;
    padding: .375rem .75rem;
    position: relative;
    width: 100%;
    height: 58px;
}

.pm-form-label__error-text {
    color: #c20000;
    display: none;
    float: left;
    font-size: 13px;
    padding-top: 0.4em;
    position: relative;
    width: 100%;
}

.visa-invalid {
    background: #ffffff url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 22.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 100 100' style='enable-background:new 0 0 100 100;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23C20000;%7D%0A%3C/style%3E%3Cpath class='st0' d='M50,0C22.4,0,0,22.4,0,50c0,27.6,22.4,50,50,50s50-22.4,50-50C100,22.4,77.6,0,50,0z M54.3,73.4h-9.5V65h9.5 V73.4z M54.3,57h-9.5V25.9h9.5V57z'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    right: 14px;
    bottom: 20px;
    width: 18px;
    height: 18px;
    background-size: 100%;
    text-indent: -20000000px;
}


/* Set dynamically */

.pm-input-field--error {
    border: 5px solid #c20000;
    height: 58px;
}

.pm-input-field--focus {
    border: 1px solid #969696;
    outline: none;
}

.pm-input-field--error.pm-input-field--focus {
    border: 5px solid #c20000;
    height: 58px;
}

.card-input__spinner__holder {
    position: relative;
    /* top: 40px; */
}

.card-input__spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: none;
}

.card-input__spinner--active {
    display: block;
}