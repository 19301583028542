/*
Custom CSS Spielbank repository
*/

/* FONTS */
@import url("https://p.typekit.net/p.css?s=1&k=jaq1ese&ht=tk&f=10294.10295.10296.10297&a=105590028&app=typekit&e=css");

@font-face {
  font-family:"brandon-grotesque";
  src:url("https://use.typekit.net/af/1da05b/0000000000000000000132df/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/1da05b/0000000000000000000132df/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/1da05b/0000000000000000000132df/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
  font-family:"brandon-grotesque";
  src:url("https://use.typekit.net/af/32d3ee/0000000000000000000132e0/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/32d3ee/0000000000000000000132e0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/32d3ee/0000000000000000000132e0/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
}

@font-face {
  font-family:"brandon-grotesque";
  src:url("https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
  font-family:"brandon-grotesque";
  src:url("https://use.typekit.net/af/383ab4/0000000000000000000132e4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/383ab4/0000000000000000000132e4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/383ab4/0000000000000000000132e4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
}

.tk-brandon-grotesque { font-family: "brandon-grotesque",sans-serif; }


/* OVERWRITES */


/* GENERAL */
body {
  color: #B9B9B9 !important;
  background: #1B4037;
  /*background: linear-gradient(#000, #1a4037) !important;*/

  font-size: 18px !important;
  /*font-size: 1.25vw !important;*/
}

.container {
  max-width: 1440px !important;
}

p {
  color: #B9B9B9;
  letter-spacing: 0;

  font-size: 18px !important;
  /*font-size: 1.25vw !important;*/

  line-height: 24px;
  /*line-height: 1.667vw !important;*/
}

p.paragraph-small {
  margin: 0;
}

hr {
  border-top: 1px solid rgb(255, 255, 255) !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
}

.actions {
  margin-top: 10px !important;
}

h1 {
  font-family: "brandon-grotesque", sans-serif !important;
  font-size: 48px !important;
  text-transform: uppercase;
  padding-top: 58px !important;
  /*padding-bottom: 58px;*/
  color: #fff;
  line-height: 56px;
}

/* Ausnahme H2 Desktop */
h1.headline {
  font-family: "brandon-grotesque", sans-serif !important;

  color: #FFFFFF !important;
  letter-spacing: 0;

  /*max-width: 50%;*/
  text-align: left !important;
  padding-top: 58px !important;
  padding-bottom: 58px;
  text-transform: uppercase;

  font-size: 40px !important;
  /*font-size: 2.778vw !important;*/

  line-height: 48px !important;
  /*line-height: 3.334vw !important;*/
}

h2 {
  font-family: "brandon-grotesque", sans-serif !important;
  font-size: 32px !important;
  color: #FFFFFF !important;
  letter-spacing: 0;
  line-height: 40px !important;
  padding: 40px 0 24px 0;
  /*max-width: 840px;*/
  text-transform: uppercase;
}

h2 span {
  /*color: #FFFFFF !important;*/
}

/* fake H3 */
.faq-header h2 {
  padding: 0px !important;
  font-family: "LatoRegular", sans-serif !important;
  text-transform: none !important;
  margin-right: 20px !important;

  font-size: 24px !important;
  /*font-size: 1.667vw !important;*/

  line-height: 32px !important;
  /*line-height: 2.223vw !important;*/
}

h3 {
  color: #fff !important;
  font-family: "LatoRegular", sans-serif !important;
  margin-bottom: 24px;
  margin-top: 48px;

  font-size: 24px !important;
  /*font-size: 1.667vw !important;*/

  line-height: 32px !important;
  /*line-height: 2.223vw !important;*/
}

h3 b {
  font-family: "LatoRegular", sans-serif !important;;
  font-weight: normal !important;
}

h3.headsm {
  color: #000 !important;
  font-size: 18px;
  text-transform: none !important;
  font-family: "LatoRegular", sans-serif !important;
}

h4.headsm {
  font-size: 24px;
  color: #000 !important;
  text-transform: none !important;
  font-family: "LatoRegular", sans-serif;
}

h4 {
  font-family: "LatoBold", sans-serif;
  font-size: 20px !important;
  line-height: 24px !important;
  color: #fff;
}

h5 {
  font-family: "LatoBold", sans-serif;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #fff;
}

a {
  color: #fff !important;;
  text-decoration: underline !important;
}
a:hover {
  text-decoration: underline !important;
}


p a {
  color: #fff !important;
}
p a:hover {
  text-decoration: underline !important;
}

/*
b, strong {
font-family: inherit !important;
}
*/

/* TABLE */
.table {
  border: 2px solid #4D605B;
  border-collapse:separate;
  border-radius:12px;
  border-spacing: 0;
  margin: 20px 0px;
}

.table tr:nth-child(even) {
  background: rgba(255,255,255,0.10);
}

.table tr td {
  color: #fff !important;
  background: none !important;
  font-size: 14px;
}

.table tr td p {
  font-size: 14px !important;
  margin:0;
}

.table thead th {
  background: none !important;
  font-family: "LatoRegular", sans-serif !important;
  font-size: 16px;
  border-bottom: 2px solid #4D605B;
  border-left:none;
  border-right:none;
}

table.table-custom tr td,
table.table-custom tr td p,
table.table-custom thead th {
  font-size: 18px !important;
  margin-bottom: 1rem;
}

.table-active, .table-active > td, .table-active > th {
  background: none !important;
}

.footer-pages .table td,
.footer-pages .table th {
  border-top: none !important;
}

.table td, .table th {
  border-top: none !important;
}


/* ROW */
.row,
.row-custom {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.row-custom > div {
  padding-left: 0px !important;
  margin-bottom: 10px;
  padding-right: 10px;
}

.get-in-contact .row-custom > div {
  padding-right: 30px;
}

.get-in-contact .row-custom > div:last-child {
  padding-right: 0px;
}


.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  padding: 0;
}

/* BUTTONS */
.btn {
  border-radius: 12px !important;
}

.btn.btn-block {
  display: inherit !important;
  width: inherit !important;
}

.btn.text-uppercase {
  text-transform: none !important;
}

.btn-main {
  padding: 6px 40px !important;
  height: 40px;
  min-width: 152px;
  background: #fff !important;
  border: 2px solid #fff !important;
  color: #000 !important;
  text-transform: none !important;
  font-size: 18px;
  text-decoration: none !important;
  text-align: center;
}

.btn-main:hover {
  background: none !important;
  border: 2px solid #fff !important;
  color: #fff !important;
  text-decoration: none !important;
}

.btn-main:active {
  background: #000 !important;
  color: #fff !important;
}

.btn-main.disabled {
  opacity: 0.5;
  color: #000 !important;
  pointer-events: none;
}

.btn-theme-outline {
  padding: 6px 20px !important;
  min-height: 40px;
  min-width: 152px;
  text-transform: none;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  color: #fff;
  background: #000;
  text-decoration: none !important;
}

.btn-theme-outline .icon svg {
  max-height: 14px;
}

.btn-theme-outline:hover {
  background: #fff !important;
  outline: 2px solid #000;
  border: 2px solid #fff !important;
  color: #000 !important;
  text-decoration: none !important;
}

.btn-theme-outline:active {
  background: rgba(255,255,255, 0.5) !important;
  border: 2px solid rgba(255,255,255, 0) !important;
  color: #000 !important;
}

.btn-theme-outline.disabled {
  opacity: 0.5;
  background: none !important;
  border: 2px solid #fff !important;
  pointer-events: none;
}

.btn-theme-outline.dark-green {
  background: #fff !important;
}

.btn-gray-fill {
  background: #fff;
  padding: 12px 24px !important;
  border-radius: 12px !important;
}

.btn-primary {
  color: #000 !important;
  background: #F1BF12;
  border-radius: 12px;
  border-color: #F1BF12;
  text-decoration: none !important;
  font-size: 18px;
  /*margin: 10px 0px;*/
}

.btn-primary:hover {
  background-color: #FDDA63;
  border-color: #FDDA63;
  color: #000;
  text-decoration: none !important;
}

.btn-primary:active {
  background: #F1BF12 !important;
  border-color: #F1BF12 !important;
  color: #000 !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background: #E3D9B5 !important;
  border-color: #E3D9B5 !important;
  color: rgba(0,0,0,0.5) !important;
  pointer-events: none;
  opacity: 1 !important;
}

.btn-secondary {
  color: #1B4037 !important;
  text-transform: none !important;
  padding: 9px 40px !important;
}

.btn-secondary.btn-md {
  padding: 6px 40px !important;
}

.btn-secondary .icon,
.btn-secondary .icon-right-arrow {
  display: none !important;
}

.btn-secondary:hover {
  color: #000;
  background-color: #FDDA63;
  border-color: #FDDA63;
}

.btn-secondary:disabled {
  background: #E3D9B5 !important;
  border-color: #E3D9B5 !important;
  color: rgba(0,0,0,0.5) !important;
  pointer-events: none;
}

.btn-secondary:active {
  background: #F1BF12 !important;
  border-color: #F1BF12 !important;
  color: #000 !important;
}

.btn-secondary:not(:disabled):not(.disabled).active {
  opacity: 1 !important;
  border-color: #F1BF12 !important;
}

.icon-euro {
  width: 12px !important;
  top: 16px !important;
  color: #000 !important;
  vertical-align: middle !important;
}

.w-100 {
  width: auto !important;
}

/* euro sign new */
.limitAmount:before {
  content: '€';
}

.form-group .form-control.is-valid + .icon-euro, .form-group .was-validated .form-control:valid + .icon-euro,
.form-group .form-control.is-invalid + .icon-euro {
  right: 52px !important;

}

.auto-typehead {
  background: #EAEAEA !important;
  border-radius: 12px;
}

.show-hide-password .icon {
  width: 24px !important;
}

.show-hide-password .icon svg {
  color: #000 !important;
}

.form-group .form-control.is-invalid + .show-hide-password,
.form-group .form-control.is-valid + .show-hide-password {
  right: 42px !important;
}

/* 404 PAGE */
.error404-main {
  padding: 10px 0 !important;
}

.error404-main .error-box {
  background: none !important;
  padding: 0 !important;
}

.error404-main .error-box .error-img {
  border-bottom: none !important;
  padding-bottom: 0px !important;
}

.error404-main .error-box .error-content {
  text-align: center;
}

.error404-main .error-box .error-content h2 {
  max-width: 100% !important;
}

.error404-main .error-box .error-content ul {
  display: inline-flex;
  list-style-type: none !important;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  padding-left: 0;
}

.error404-main .error-box .error-content li::after {
  content: "|";
}

.error404-main .error-box .error-content li:last-child:after
{
  content: '';
}

.error404-main .error-box .error-content li a {
  margin: 0 14px;
}

.service-body-main {
  margin: 0 !important;
}

.service-body-main .row-custom > div {
  margin: 10px 1.5em !important;
  padding: 0 !important;
}

.service-body-main .service-inner {
  padding: 8px !important;
  border-radius: 12px !important;
  background: none !important;
  font-size: 18px !important;
  border: 2px solid #fff;
}

/* GAME LIST */
.game-list-menus {
  background: none !important;
  box-shadow: none !important;
  padding: 30px 0px !important;
  margin-bottom: 0px !important;

}

/* Alternative: Tabs for game categories */
.game-list-menus .menus-main {
  text-transform: none !important;
  font-size: 18px !important;
  min-height: 60px;
  grid-gap: 15px !important;
}

.game-list-menus .menus-main .menus-inn .btn {
  background: none !important;
  border: none !important;
  border-radius: 20px !important;
  border: 2px solid #4D605B !important;
  color: #fff !important;
  font-family: "LatoRegular", sans-serif !important;
  font-size: 16px !important;
  padding: 3px 10px !important;
  flex-direction: column;
  width: 100% !important;
  line-height: 24px !important;
}

.game-list-menus .more-cat {
  margin:20px 4px;
}

.game-list-menus .more-cat span {
  font-size: 16px;
  color: #fff;
}

.game-list-menus .more-cat .upwords {
  transform: rotate(-180deg);
  margin-top: 0 !important;
}

.game-list-menus .more-cat .upwords svg {
  margin-top: -7px;
}

.game-list-menus .menus-main .menus-inn .btn.active {
  /*border-bottom: 2px solid #fff !important;*/
  background: #4D605B !important;
}

/* hide specific categories */
/*
.game-list-menus .menus-main .menus-inn:nth-child(9),
.game-list-menus .menus-main .menus-inn:nth-child(10),
.game-list-menus .menus-main .menus-inn:nth-child(11),
.game-list-menus .menus-main .menus-inn:nth-child(12) {
display: none;
}
*/


/* PAGINATION */
.paginate ul {
  float: left !important;
}

.paginate ul li {
  font-size: 16px !important;
}

.paginate ul li.current {
  background: none !important;
  color: #f1bf13;
  font-size: 16px !important;
}

.paginate ul li a:hover {
  background: none !important;
  color: #fff !important;
  border-radius: 6px;
}

.game-list-menus .type-scroll-left {
  margin-top: -8px !important;
  left:12% !important;
}

.game-list-menus .type-scroll-left .icon {
  height: 50px !important;
  width: 50px;
  color: #fff !important;
  background-image: url("https://spielbank.dmcgroup.eu/arrow_circle_left.svg") !important;
  background-repeat: no-repeat !important;
  background-size: 50px;
}

.game-list-menus .type-scroll-left .icon svg {
  display: none;
}

.game-list-menus .type-scroll-right {
  margin-top: -8px !important;
  right:12% !important;
}

.game-list-menus .type-scroll-right .icon {
  height: 50px !important;
  width: 50px;
  color: #fff !important;
  background-image: url("https://spielbank.dmcgroup.eu/arrow_circle_right.svg") !important;
  background-repeat: no-repeat !important;
  background-size: 50px;
}

.game-list-menus .type-scroll-right .icon svg {
  display: none;
}

.game-detailbt .full-screen-btn .icon {
  color: #000;
}

.game-playbt .btn-theme-outline {
  background-image: url("https://spielbank.dmcgroup.eu/heart.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 12px center !important;
  background-size: calc(1.5rem) calc(1.5rem) !important;
  width:52px !important;
  max-width:52px !important;
  min-width:52px !important;
}

.game-playbt .btn-theme-outline:hover {
  background-image: url("https://spielbank.dmcgroup.eu/heart_black.svg") !important;
}

.game-playbt .btn-theme-outline.favourite-added {
  background-image: url("https://spielbank.dmcgroup.eu/heart_fill.svg") !important;
}

/* text */
.game-playbt .btn-theme-outline .fav-desktop,
.game-playbt .btn-theme-outline .fav-mobile {
  display: none !important;
}

.game-playbt .icon {
  display: none;
}

.modal-close-bt {
  background: none !important;
  height: 42px !important;
  width: 42px !important;
}

/* CHAT */
.chat-main {
  display: none !important;
}

/* MODAL */
.modal-content .modal-header .close {
  right: 20px !important;
  top: 20px !important;
  padding: 0px !important;
}

.user-profile .layout-nav .nav-sidebar .close-icon {
  right: 20px !important;
  top: 16px !important;
}


.modal-title {

}

.modal-content {
  background: rgba(26,64,55) !important;
  border: 2px solid #979797 !important;
}

.modal-content .modal-header {
  background: #000 !important;
  padding: 16px 50px !important;
}

.modal-content .modal-header h2 {
  font-size: 24px !important;
  line-height: 32px !important;
  padding: 4px !important;
  text-align: center;
  width: 100%;
  max-width: 100%;
}

.modal-content .modal-body {
  max-width: 1200px;
  background: linear-gradient(rgba(0,0,0,0.36), rgba(26,64,55,0.36)) !important;
  padding: 38px 32px !important;
}

.modal-content .modal-body .flex-fill {
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;
}

.modal-body .forgot-password-lnk {
  text-align: center;
  margin-bottom: 0px !important;
  margin-top: 24px !important;
}

.modal-body .ft-terms {
  padding-bottom: 0px !important;
  text-align: center;
}

.forgot-main-modal .modal-content .ft-terms {
  text-align: left;
}

.forgot-main-modal .modal-content p {
  padding-top: 0px !important;
  line-height: inherit !important;
}

.modal-body {
  color: #fff !important;
}

.modal-body p {
  color: #fff !important;
}

.modal-full-height .modal-body p {
  color: #000 !important;
}

.modal-body a {
  color: #fff !important;
  font-family: "LatoBold", sans-serif !important;
}

.modal-full-height .modal-body a {
  color: #000 !important;
}

.modal-body h2 {
  color: #fff !important;
  word-break: break-word !important;
}

.modal-full-height .modal-body h2 {
  color: #000 !important;
  word-break: break-word !important;
}

.modal-content .register-footer {
  margin-top:40px;
}

.modal-content .register-footer ul li {
  padding: 0px 0px !important;
  display: inline;
}

.modal-content .register-footer ul li:before {
  content: "|";
  padding: 0px 16px;
}

.modal-content .register-footer ul li:first-child {
}

.modal-content .register-footer ul li:first-child:before {
  content: "";
  padding: 0px 0px;
}

.modal-body .trans-details-box {
  background: none !important;
  padding: 0 !important;
  color: #fff !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #fff !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.modal-footer {
  justify-content: center !important;
}

.verification-modal .modal-dialog {
  max-width: 1200px !important;
}

/* Email verification */
.verification-main {
  margin: 0 auto;
  padding-top: 40px;
  max-width: 840px !important;
}

.verification-main .box-bg {
  margin: 0 auto;
  background: #fff !important;
}

.verification-main .box-bg h3 {
  color: #000 !important;
}

.verification-main .box-bg .verification .status-icon {
  color: #B9B9B9 !important;
}

.verification-modal .modal-body p.mb-3 {
  margin: 0 auto !important;
  margin-top: 40px !important;
  max-width: 840px;
}

.verification-main .box-bg .logo-image.pxl-logo {
  max-width: 80px !important;
}


/* TOAST */
.toast-container .ngx-toastr.toast-success, .toast-container .ngx-toastr.toast-error, .toast-container .ngx-toastr.toast-info, .toast-container .ngx-toastr.toast-warning {
  background-image: none;

}

.toast-container .ngx-toastr {
  margin: 0 0 6px;
  padding: 24px !important;
  width: 320px !important;
  border-radius: 12px !important;
  box-shadow: none !important;
}

.toast-container .ngx-toastr.toast-success {
  background-color: #478024 !important;
}

.toast-container .ngx-toastr.toast-error {
  background-color: #C82532 !important;
}

.toast-close-button {
  position: relative;
  right: -12px !important;
  top: -24px !important;
  float: right;
  font-size: 28px !important;
  font-weight: normal !important;
  font-family: "LatoRegular", sans-serif !important;
  color: #FFFFFF;
  text-shadow: none !important;
  opacity: 1 !important;
}


/* CHAT */
.chat-help .chat-text {
  color: #fff !important;
}

.chat-help .chat-icon {
  color: #fff !important;
}


/* FORMS */
.form-group,
.col-lg-6 .row .form-group,
.limits-main .col-lg-12 .form-group {
  margin-top: 20px !important;
  margin-bottom: 40px !important;
}

.row .col-lg-6 .form-group {
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}

.col-md-12 .form-group {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}


.form-group > #securityQuestion,
.form-group > #accountHolder {
  margin-top: 20px !important;
  /*margin-bottom: 40px !important;*/
}


.form-group.mb-3 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.form-group .form-control {
  font-family: "LatoRegular", sans-serif !important;
  /*min-height: 60px !important;*/
  height: auto !important;
  border-radius: 12px !important;
  padding: 12px 14px !important;
  border: 2px solid #CECECE !important;
  font-size: 18px;
}

.form-group .form-control.col-sm-3 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

label.form-fake-label {
  font-family: "LatoRegular", sans-serif !important;
  margin-left: 16px !important;
  left: 0px !important;
  font-size: 14px !important;
  color: #fff !important;
  margin-top: -22px;
  opacity: 1 !important;
  visibility: visible !important;
}


.modal-full-height .form-group.custom label {
  color: #000 !important;
}

.form-group p {
  margin-top: 10px;
}

.form-group input {
  /*height: auto !important;*/
  font-family: "LatoRegular", sans-serif !important;
}

.form-group select.form-control {
  font-family: "LatoRegular", sans-serif !important;
  padding-top: 18px;
  padding: 14.5px 14px !important;
  color:#000 !important;
}

.form-group .custom-select {
  padding:10px;
  height: auto !important;
}

.form-group textarea.form-control {
  color: #000;
}

.text-danger {
  font-size: 12px !important;
  padding-left: 19px !important;
  padding-right: 19px !important;
  color: #C82532 !important;
}

/*
.bg-box .text-danger div {
color: #C82532 !important;
}
*/

.form-group .form-control.is-invalid {
  background-color: #fff;
}

.form-control.is-valid, .was-validated .form-control:valid {
  background-image: url("http://spielbank.dmcgroup.eu/valid.svg") !important;
  /*background-size: auto !important;*/
  background-position: right calc(1rem) center !important;
  background-size: calc(1.5rem) calc(1.5rem) !important;
  background-repeat: no-repeat !important;
  border: 2px solid #478024 !important;
}

.form-group .form-control.is-invalid {
  background-image: url("http://spielbank.dmcgroup.eu/error.svg") !important;
  /*background-size: auto !important;*/
  background-position: right calc(1rem) center !important;
  background-size: calc(1.5rem) calc(1.5rem) !important;
  background-repeat: no-repeat !important;
  border: 2px solid #C82532 !important;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #fff !important;
  color: #757575 !important;
}

.input-error-border {
  box-shadow: none !important;
}

.no-data-msg {
  background: #fff !important;
  border-radius: 12px !important;
  padding: 12px 20px !important;
  border: 2px solid #C82532 !important;
  color: #C82532 !important;
  font-size: 14px !important;
  background-image: url("http://spielbank.dmcgroup.eu/warning.svg") !important;
  background-position: left calc(1rem) center !important;
  background-repeat: no-repeat !important;
  padding-left: 4rem !important;
}

.info-note {
  background: #fff !important;
  border-radius: 12px !important;
  padding: 10px 15px 10px 4rem !important;
  border: 2px solid #0F7FAB !important;
  color: #0F7FAB !important;
  font-size: 14px !important;
  background-color: none !important;
  background-image: url("http://spielbank.dmcgroup.eu/information.svg") !important;
  background-position: left calc(1rem) center !important;
  background-repeat: no-repeat !important;
  margin: 20px 0;
}

.info-note .icon {
  display: none !important;
}

.form-group .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #757575;
  opacity: 1; /* Firefox */
}

.form-group .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #757575;
}

.form-group .form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: #757575;
}

.input-group-text {
  margin-left: 10px;
  border-radius: 12px !important;
}

/* HEADER */

.grid-header .btn-primary {
  margin-bottom: 0;
}

.main-header {
  /*height: auto !important;*/
  background: #000 !important;
  margin-bottom: 0px !important;
}

.main-header .navbar-brand .logomain {
  padding: 6px 0 !important;
}

.main-header .header-item .nav-item a.search-icon {
  width: 32px !important;
  height: 40px !important;
  border-radius: 0 !important;
  background-image: url("https://spielbank.dmcgroup.eu/search_white.svg") !important;
  background-repeat: no-repeat !important;
}

.main-header .header-item .nav-item a.search-icon:hover,
.main-header .header-item .nav-item a.search-icon.is-active {
  border-bottom: 4px solid #fff !important;
  padding-bottom: 8px !important;
}

.main-header .header-item .nav-item a.search-icon .icon svg {
  display: none;
}

.main-header .header-item .nav-item a {
  text-transform: none !important;
  /*padding: 0 16px !important;*/
  padding: 9px !important;
  padding-top: 0px !important;
  margin-top: 4px;
  border-bottom: 4px solid #000;
  padding-bottom: 8px !important;
  text-decoration: none !important;
}

.main-header .header-item .nav-item a.is-active {
  border-bottom: 4px solid #fff;
  padding-bottom: 8px !important;
}

.main-header .header-item .nav-item a:hover {
  border-bottom: 4px solid #fff;
  padding-bottom: 8px !important;
}

.main-header .header-item .nav-item.user-menu .icon-deposit {
  margin-top: 5px !important;
}

.main-header .header-item .nav-item.user-menu .icon {
  margin-top: 5px !important;
  color: #fff;
}

/* money icon */
.main-header .header-item .nav-item.user-menu.with-count .nav-link {
  color: #fff !important;
  background: #000 !important;
  margin: 0 !important;
  border: 2px solid #fff !important;
  border-radius: 12px !important;
}

.main-header .header-item .nav-item.user-menu.with-count .nav-link .count-text {
  padding-top: 7px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.main-header .header-item .nav-item.user-menu.with-count .nav-link:hover {
  background: #fff !important;
  /*border: 2px solid #fff !important;*/
}

.main-header .header-item .nav-item.user-menu.with-count .nav-link:hover .count-text {
  color: #000 !important;
}

.main-header .header-item .nav-item.user-menu .icon-deposit {
  margin-top: 5px !important;
}

.main-header .header-item .nav-item.user-menu.with-count .nav-link .icon {
  color: #fff !important;
  width: 23px;
  min-width: 23px !important;
}

/* user icon */
.main-header .header-item .nav-item.user-menu.account .nav-link {
  height:45px;
  width: 45px;
}

.main-header .header-item .nav-item.user-menu.account .nav-link {
  border: 0 !important;
  background-image: url("http://spielbank.dmcgroup.eu/user.svg") !important;
  background-size: 45px !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.main-header .header-item .nav-item.user-menu.account .nav-link:hover {
  background: none !important;
  background-image: url("http://spielbank.dmcgroup.eu/user_active.svg") !important;
  background-size: 45px !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.main-header .header-item .nav-item.user-menu.account .nav-link .icon {
  display: none;
}

.main-header .header-item .nav-item.user-menu.with-count .nav-link:hover .icon-deposit,
.main-header .header-item .nav-item.user-menu.with-count .nav-link:hover .icon {
  color: #000 !important;
}


/* BOTTOM NAV MOBILE */
.nav-bottom-body .nav-bottom-inner .panel-group .panel-default .panel-heading a {
  text-decoration: none !important;
  padding: 13px !important;
}

.main-swiper-wrap {
  /*
  min-height: 41.667vw !important;
  max-height: 41.667vw !important;
  height: 41.667vw !important;
  */


  min-height: 32.987vw !important;
  max-height: 32.987vw !important;
  height: 32.987vw !important;

}

.main-swiper {
  max-width: 100% !important;
}

.swiper-content-bg {
  display: none;
}

.swiper-content.container {
  max-width: 100% !important;
}

.swiper-container .swiper-wrapper .swiper-slide .swiper-content .swiper-image::before {
  background: none !important;
}

.swiper-info .info {
  background: none !important;
  /*padding: 12% 5% 8% 55% !important;*/
  padding: 7% 5% 8% 50% !important;
  margin:0 !important;
  max-width: 100% !important;
}

.swiper-info .tnc-teaser {
  position: absolute;
  bottom: 10px !important;
  right: 5px !important;
  /*font-size: 14px;*/
  font-size: 0.973vw !important;
  line-height: 16px;
  margin: 0 !important;
}

.swiper-info .tnc-teaser p {
  margin: 0 !important;
}

.swiper-info .info .actions .btn-main {
  color: #000 !important;
  background: #F1BF12 !important;
  border-radius: 12px !important;
  border-color: #F1BF12 !important;
  text-decoration: none !important;
  font-size: 18px !important;
  height: 56px !important;
}

.swiper-info .info .actions .btn-main:hover {
  background-color: #FDDA63 !important;
  border-color: #FDDA63 !important;
  color: #000;
  text-decoration: none !important;
}

.swiper-info .info .actions .btn-main:active {
  background: #F1BF12 !important;
  border-color: #F1BF12 !important;
  color: #000 !important;
}

.swiper-info .info .actions  {
  margin-top: 2vw !important;
}

.swiper-info .title {
  font-family: "brandon-grotesque", sans-serif !important;
  color: #FFFFFF !important;
  letter-spacing: 0;

  /*font-size: 48px !important;*/
  font-size: 3.334vw !important;

  /*line-height: 56px !important;*/
  line-height: 4vw !important;
}

.swiper-info .desc {
  color: #FFFFFF !important;

  /*font-size: 24px !important;*/
  font-size: 1.667vw !important;

  /*line-height: 32px !important;*/
  line-height: 2.2vw !important;

  /*margin: 24px 0px !important;*/
  margin: 1.2vw 0px 1.667vw !important;
}

.swiper-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  max-width: 100% !important;
  transform: translateX(-50%);
  pointer-events: none;
  background: center/cover no-repeat;
  background-image: none;
  width: 100%;
  opacity: 1.0 !important;
}

/*
.swiper-image {
max-width: 100% !important;
background-position: inherit !important;
}
*/


.slot-banner {
  display: none;
}

.seprater {
  display: none;
}

.page-main.promotions {
  background: #000 !important;
  /*background: url("https://spielbank.dmcgroup.eu/bg_black.png") repeat #000 !important;*/

}

.grid-main-wrap {
  padding-bottom: 80px;
  /*background: #1a4037 !important;*/
  background: linear-gradient(rgba(0,0,0,0.36), rgba(26,64,55,0.36)) !important;
  /*background: linear-gradient(rgba(0,0,0,1), rgba(26,64,55,1)) !important;*/
}

.page-wrap.page-layout {
  /*background: #1a4037 !important;*/
  /*background: linear-gradient(#000, #1a4037) !important;*/
  background: linear-gradient(rgba(0,0,0,0.36), rgba(26,64,55,0.36)) !important;
  /*background: linear-gradient(rgba(0,0,0,1), rgba(26,64,55,1)) !important;*/
  padding: 0px !important;
  margin: 0px !important;
}

.page-wrap.page-layout.gameplay {
  background: linear-gradient(rgba(0,0,0,0.36), rgba(26,64,55,0.36)) !important;
}

.page-wrap.page-layout.gameplay .game-detail {
  background: #000 !important;
  /*background: linear-gradient(rgba(0,0,0), rgba(26,64,55)) !important;*/
  /*background: #1a4037 !important;*/
  padding: 2px 0;
}

.is-fullscreen-mode {
  background: #000;
}

.is-fullscreen-mode .lobbyIFrame{
  min-height: calc(88vh) !important;
}

/* smaller gameplay nav */
.page-wrap.page-layout.gameplay .game-detail .page-main.container {
  /*padding: 0px 94px 0px 94px !important;*/
  padding: 0px 16px 0px 16px !important;
}

.game-detail {
  background: #000;
}

.game-detail-main .game-detail-inner .game-img img {
  max-height: auto !important;
}

.grid-main-wrap .page-main {
  padding: 0 94px !important;
}

.grid-main-wrap .game-category-main {
  background: #000 !important;
  /*background:url("https://spielbank.dmcgroup.eu/bg_black.png") repeat #000 !important;*/
  padding-bottom: 58px !important;
}

.grid-main-wrap .game-category-main .container {
  padding: 0px 94px 0px 94px !important;
}

.grid-main-wrap .game-category-main .container p {
  padding: 10px 0px;
}

/* GAME TILES RESIZING */

.grid-main-wrap .size-1x1 .content .image,
.grid-main-wrap .size-1x2 .content .image,
.grid-main-wrap .size-2x1 .content .image,
.grid-main-wrap .size-2x2 .content .image {
  height: 100%;
  width: 100%;
}

.grid-main-wrap .is-css-grid {
  grid-template-columns: repeat(5, 1fr) !important;
  grid-auto-flow: initial;
  grid-gap: 20px !important;
  /*grid-auto-columns: 226px !important;*/
}

.grid-main-wrap .size-1x1 .content,
.grid-main-wrap .size-1x2 .content,
.grid-main-wrap .size-2x1 .content,
.grid-main-wrap .size-2x2 .content {
  min-height: inherit !important;
}

.grid-main-wrap .size-1x1 .content {
  width: 100%;
  height: calc(15.5vw);
  max-height: 234px;
  max-width: 234px;
}

.grid-main-wrap .size-2x1 .content {
  height: calc(15.5vw);
  max-height: 226px;
}


.grid-main-wrap .size-1x1::before,
.grid-main-wrap .size-1x2::before,
.grid-main-wrap .size-2x2::before {
  padding-bottom: 0px !important;
}

.grid-main-wrap .is-1x1 .show-on-hover,
.grid-main-wrap .is-1x2 .show-on-hover,
.grid-main-wrap .is-2x1 .show-on-hover {
  background: linear-gradient(225deg, rgba(0,0,0,0.80) 0%, rgba(0,0,0,0.00) 40%) !important;
}

/* search categories */
.search-body .search-border {
  background: url("https://spielbank.dmcgroup.eu/bg_black.png") repeat #000 !important;
  padding: 30px 0px;
  border: none !important;
}

.search-body .searchbar-main {
  width: 85%;
  padding: 0px !important;
}

.search-body .searchbar-main .input-group input {
  background-image: url("https://spielbank.dmcgroup.eu/search_black.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 14px center !important;
  padding-left: 48px !important;
}

.search-body .searchbar-main .input-group > button {

}

.search-body .searchbar-main .searchbar-icon {
  display: none;
}

.search-body .searchbar-main .input-group {
  max-width: 100vw !important;
}

.search-body .search-border .searchbar-main .back-search {
  display: none !important;
}

.grid-main-wrap .grid-content.is-scrollable.categories {
  padding-top: 0px !important;
}

.grid-main-wrap .is-css-grid.no-scroll.has-max-columns-4 {
  margin: 0px 0 !important;
}

.grid-main-wrap .is-css-grid.category-grid .size-1x1 {
  background: none !important;
  border: none !important;
  border-radius: 20px !important;
  border: 2px solid #4D605B !important;
  color: #fff !important;
  padding: 0px !important;
  overflow: visible !important;
}

.grid-main-wrap .is-css-grid.category-grid {
  grid-gap: 16px !important;
  display: flex !important;
  flex-wrap: wrap !important;
}

.grid-main-wrap .categories .content.game-item {
  background: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  min-height: inherit !important;
}

.grid-main-wrap .categories .game {
  color: #fff !important;
  white-space: nowrap;
}

.grid-main-wrap .categories .cat-title {
  font-family: "LatoRegular", sans-serif !important;
  font-size: 15px !important;
  padding: 5px 10px !important;
  line-height: inherit !important;
}

.grid-main-wrap .grid-content .content {
  position: relative !important;
}


.grid-main-wrap .container {
  padding: 0px 94px 0px 94px !important;
}

.grid-main-wrap .container.pb-0 {
  padding-bottom: 0px !important;
}

.grid-main-wrap .gamesection {
  padding: 0px 94px 0px 94px !important;
  max-width: 1440px !important;
}

.games-provider-list {
  background: #183F37 !important;
  padding-top: 30px !important;
  padding-bottom: 40px !important;
}

/*
.games-provider-list .game-provider-body {
grid-gap: 40px !important;
}
*/

.games-provider-list .game-provider-body .size-1x1 {
  width: 19vw !important;
  max-width: 280px;
}

/* hide specific providers */
.games-provider-list .game-provider-body .size-1x1:nth-child(5) {
  display: none !important;
}

.games-provider-list .headline .headline-border {
  text-transform: uppercase !important;
  font-family: "brandon-grotesque", sans-serif !important;
  font-size: 32px !important;
  line-height: 40px !important;
}

.grid-main-wrap .games-provider-list .grid-content .content.game-item {
  padding: 0.5vw 10% !important;
  background: #4D605B !important;
  border: 3px solid #4D605B;
  border-radius: 47px !important;
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.50) !important;
}

.grid-main-wrap .games-provider-list .grid-content .content.game-item:hover {
  background: none !important;
  box-shadow: 0 0 0 0 !important;
  border: 3px solid #4D605B;
}

.games-provider-list .content.game-item .image img {
  /*height: 12vw;*/
  max-height: 70px;
}

.grid-main-wrap .gamesection.recommaned-games {
  padding: 0px !important;
}

.grid-main-wrap .gamesection .page-main.container {
  padding: 15px 0px !important;
}

.search-body {
  display: none;
}

.grid-main-wrap .grid-header .headline {
  text-align: left !important;
  max-width: 55vw !important;
}

.grid-main-wrap .grid-header h2.headline.with-button,
.grid-main-wrap .grid-header h1.headline.with-button {
  max-width: 100% !important;
  font-family: "LatoBold", sans-serif !important;
  font-size: 24px !important;
  line-height: 32px !important;
  text-transform: none !important;
  padding: 20px 0px !important;
}

.grid-main-wrap .grid-header .game-search {
  border-bottom: 2px solid #B9B9B9 !important;
  padding-left: 0px !important;
}

.grid-main-wrap .grid-header .game-search input {
  background: none !important;
  color: #fff !important;
  padding-left:32px;
  font-size: 18px !important;
}

.grid-main-wrap .grid-header .game-search {
  background-image: url("https://spielbank.dmcgroup.eu/search_white.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 0 center !important;
  margin-bottom: 30px !important;
}

.grid-main-wrap .grid-header .game-search .searchbar-icon svg {
  display: none;
}


.game-search .clear-search {
  color: #fff !important;
  height: 15px !important;
  width: 15px !important;
  line-height: 15px !important;
}

.game-search .spinner {
  height: 20px !important;
  width: 20px !important;
  line-height: 20px !important;
  padding: 0 !important;
  margin: 0 !important;
}

.grid-main-wrap .game-info a.game-demo-link {
  text-decoration: none !important;
}

.grid-main-wrap .game-list-menus .container {
  /*padding-top: 20px !important;
  padding-bottom: 20px !important;*/
}



/* GAMEPLAY */

.grid-main-wrap .grid-header h2.gameplay-header {
  text-align: left !important;
  max-width: 100% !important;
  font-size: 18px !important;
  padding: 0 !important;
  margin: 0 !important;
  margin-top: 4px !important;
  text-transform: none !important;
  font-family: "LatoRegular", sans-serif !important;
}

.grid-main-wrap .grid-header h2.gameplay-header div {
  display: none;
}

h2.back-arrow-head .back-header {
  background: none !important;
  padding: 0 !important;
  position: relative !important;
  left:0 !important;
  width: auto;
}

h2.back-arrow-head .back-header label {
  padding: 0px 10px;
  margin: 6px;
  vertical-align: middle;
  font-size: 18px !important;
}


h2.back-arrow-head .back-header span.icon {
  min-width: 24px !important;
  width: 24px !important;
}

h1.headline .headline-border::after {
  display: none;
}

.gameplay .page-main .grid-header {
  padding: 2px 0 !important;
}

.gameplay .page-main .grid-header h2.back-arrow-head .back-header span {
  color: #fff !important;
  height: 40px !important;
}

.gameplay a.inactive-icon {
  display: none !important;
}

.gameplay .page-main .player-active-counter {
  color: #fff !important;
  text-decoration: none !important;
  font-size: 18px !important;
}

.gameplay .page-main .fav-icon {
  min-width: 40px !important;
  color: #000 !important;
  background: #000;
  background-image: url("http://spielbank.dmcgroup.eu/heart.svg") !important;
  background-size: 20px !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.gameplay .page-main .fav-icon:hover {
  background-image: url("http://spielbank.dmcgroup.eu/heart_fill.svg") !important;
}

.gameplay .page-main .fav-icon.favourite-added {
  background: #fff;
  background-image: url("http://spielbank.dmcgroup.eu/heart_fill.svg") !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}

.gameplay .page-main .fav-icon .icon {
  width: 20px !important;
  display: none;
}

.gameplay .page-main .full-screen-btn {
  width: 20px !important;
  color: #000 !important;
  border: 2px solid #fff !important;
  background: #000 !important;
  background-image: url("http://spielbank.dmcgroup.eu/fullscreen_white.svg") !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: 24px !important;
  margin-left: 10px;
}

.is-fullscreen-mode .game-detailbt .full-screen-btn {
  background: #FFF !important;
  background-image: url("http://spielbank.dmcgroup.eu/fullscreen.svg") !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: 24px !important;
}

.gameplay .page-main .full-screen-btn .icon {
  width: 20px !important;
  display: none;
}

.gameplay .page-main .fav-icon .btn-text {
  display: none;
}

.panic-btn #item {
  width: 32px !important;
  height: 32px !important;
  border: 0 !important;
  background: none !important;
  background-image: url("http://spielbank.dmcgroup.eu/arrow_in_circle.svg") !important;
  background-position: left 0 center !important;
  background-size: 32px 32px !important;
  background-repeat: no-repeat !important;
}

.panic-btn .track_drag {
  height: 40px !important;
  width: 220px !important;
  border: solid 2px #fff !important;
  color: #fff !important;
  background: none !important;
}

.panic-btn .track_text {
  max-width: 100% !important;
  text-transform: none !important;
  font-size: 18px !important;
  letter-spacing: 0 !important;
}

.info-modal .modal-content .modal-body .timeCount {
  font-family: "brandon-grotesque", sans-serif !important;
  color: #fff !important;
  font-size: 64px !important;
  opacity: 1 !important;
}

.title-section {
  font-family: "brandon-grotesque", sans-serif !important;
  font-size: 40px !important;
  color: #FFFFFF !important;
  letter-spacing: 0;
  line-height: 48px !important;
  max-width: 66%;
  text-align: left !important;
  padding-top: 80px !important;
  text-transform: uppercase;
}

.grid-header p {
  max-width: 40%;
  text-align: left !important;
  color: #fff;
  font-size: 18px !important;
  line-height: 32px !important;
  /*line-height: 2.223vw !important;*/
}

.grid-header p ul {
  padding-left: 28px;
}

.grid-main-wrap .gamesection {

}

.grid-main-wrap .gamesection .content {
  margin: 0 !important;
  padding: 0 !important;
}

.grid-main-wrap .grid-content .content.game-item {
  background: #FFFFFF;
  box-shadow: 0 7px 11px 0 rgba(0, 0, 5, 0.83) !important;
  border-radius: 16px;
}

.grid-main-wrap .grid-content .content.game-item .image img {

}

.grid-main-wrap a.game-item-link,
.grid-main-wrap a.game-item-link:hover {
  text-decoration: none !important;
}

.grid-main-wrap .game-item-btn {
  margin: -16px;
  font-size: 16px;
  opacity: 1;
}

.grid-main-wrap .game-item-btn .item-btn-inn {
  font-size: inherit !important;
  padding: 8px;
}

.grid-main-wrap .game-item-btn .item-btn-inn:hover {
  color: #000;
  background: #fff !important;
  opacity: 1;
}



/* game detail list */
.grid-main-wrap .grid-content.is-scrollable .game-items-list .gallery {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 16px;
  scrollbar-width: auto !important;
}

/* start page list */
/*
.grid-main-wrap .grid-content.is-scrollable .game-items-list.modal-gallery .gallery {
padding-top: 0px !important;
padding-left: 15px !important;
padding-right: 15px !important;
}
*/

.anymore-questions {
  background-color: #1a4037 !important;
  padding-top: 10px !important;

  font-family: "brandon-grotesque", sans-serif !important;
  font-size: 32px !important;
  color: #FFFFFF !important;
  letter-spacing: 0;
  line-height: 40px !important;
  text-transform: uppercase;
}

.grid-main-wrap .grid-content .game-title {
  display: none;
}

.footer {
  background: #1a4037 !important;
}

.footer .language-list {
  display: none !important;
}

.footer .payment-options-main {
  background: #000 !important;
  padding-bottom: 0px !important;
  padding-top: 60px !important;
  background: url("https://spielbank.dmcgroup.eu/bg_black.png") repeat #000 !important;
}

.footer .payment-options-main .payment-row .payment-logo-main {
  max-width: 1440px;
  /* padding: 0px 78px;*/
  padding: 0px 28px;
  grid-template-columns: repeat(5, 1fr) !important;
}

.footer .payment-options-main .footer-legal-title {
  color: #fff !important;
  font-size: 24px !important;
  line-height: 32px !important;
  margin-bottom: 40px;
  font-weight: normal !important;
}

.footer .payment-options-main .footer-payment-text {
  padding: 0 78px !important;
}

.footer .payment-options-main p {
  color: #B9B9B9 !important;
}

.footer .payment-row {
  background: #1a4037 !important;
  padding: 50px 0px 28px !important;
}

.footer .payment-row .payment-item:nth-child(6),
.footer .payment-row .payment-item:nth-child(7),
.footer .payment-row .payment-item:nth-child(8),
.footer .payment-row .payment-item:nth-child(9),
.footer .payment-row .payment-item:nth-child(10),
.footer .payment-row .payment-item:nth-child(11),
.footer .payment-row .payment-item:nth-child(12),
.footer .payment-row .payment-item:nth-child(13),
.footer .payment-row .payment-item:nth-child(14),
.footer .payment-row .payment-item:nth-child(15),
.footer .payment-row .payment-item:nth-child(16){
  display: none;
}

.footer .game-logo {
  display: none !important;
}

.footer .link-section .footer-logo {
  display: none;
}

.footer .footer-list-item .footer-menu-head {
  font-family: "brandon-grotesque", sans-serif !important;
  margin-bottom: 28px !important;
  font-size: 24px !important;
}

.footer .link-section ul li {
  text-transform: none !important;
}

.footer .link-section ul li a {
  font-size: 16px !important;
  text-decoration: none !important;
  padding: 4px 10px !important;
}

.footer .container {
  padding: 0px 78px !important;
}

.footer .footer-body {
  grid-template-columns: 25% 25% 25% 25% !important;
  text-align: center;
}

.footer .footer-address {
  text-transform: none !important;
  font-size: 16px !important;
  line-height: inherit !important;
  color: #B9B9B9 !important;
}

.footer .link-section .about-logo {
  grid-gap: 40px !important;
  padding: 28px 0 50px !important;
}

.footer .link-section .about-logo .about-item:nth-child(1) {
  display: none;
}

.footer .link-section .game-logo {
  grid-gap: 40px !important;
}

.footer-sticky-menu-body {
  background: #000 !important;
  padding: 4px 0 !important;
}

.footer-sticky-menu-body .container .footer-sticky-menu .footer-item .nav-item .nav-link {
  text-decoration: none !important;
  padding: 2px 10px !important;
}

.footer .about-logo .about-item .icon {
  width: inherit !important;
  height: 50px;
}

.nav-bottom-body {
  height: calc(100% - 60px) !important;
}

.footer .payment-options-main .social-list li a {
  background: none !important;
  height: 70px !important;
  width: 70px !important;
}

.footer .link-section .footer-info-main {
  padding-top: 0 !important;
}

/* ABOUT */

.about-body {
  background-image: none !important;
  background-color: #1a4037;
}

.about-body .about-main .abt-content {
  max-width: 840px;
}

.aboutpage .accordian-list {
  display: none;
}

.gameexp-bg {
  display: none;
}

.about-body .about-main .box-main-body .box-bg-item {
  background: #F1BF12 !important;
}

.about-main .about-banner-main .about-content {
  background: none !important;
}

.about-main .box-main-body {
  padding-top: 0px !important;
  padding-bottom: 40px !important;
}

.page-layout .container {
  padding: 40px 94px 40px 94px !important;
}

.page-layout .page-content {
  max-width: 840px;
  padding-top: 0px !important;
}

.about-main .page-content {
  max-width: 100%;
}

.about-main .about-banner-main {
  padding: 0px !important;
}

/* SUPPORT */
.section-inner {
  padding: 0px !important;
  width: 840px;
}

.game-detail-main {
  background: #000 !important;
  border-radius: 12px !important;
  color: #fff !important;
  margin-top: 40px !important;
  margin-bottom: 0px !important;
  padding: 30px 0px !important;
}

.game-detail-main .game-detail-inner .game-img .game-detail-actionbt {
  display: block !important;
  position: relative !important;
}

.similar-games {
  /*background: linear-gradient(#000, #1a4037) !important;*/
  background: linear-gradient(rgba(0,0,0,0.36), rgba(26,64,55,0.36)) !important;
  padding-bottom: 20px;
}

.footer-pages .custom-frame {
  margin: 20px 0px;
  border: 2px solid #fff;
  border-radius: 12px;
  padding: 5px 20px;
}

.footer-pages .custom-frame  h3 {
  margin: 24px 0px;
}

.footer-pages .bg-box {
  background: none !important;
}

.footer-pages .bg-box .grid-header {
  display: none !important;
}

.footer-pages .bg-box p {
  color: inherit !important;
}

.page-content {
  /*padding-bottom: 80px !important;*/
}

.box-bg {
  background: #F1BF12 !important;
}

.get-in-contact .box-bg {
  background: #fff !important;
}

.box-bg p {
  color: #000 !important;
}

.box-bg a {
  color: inherit !important;
  font-weight: bold;
}

.bg-box {
  padding: 0px !important;
  background: none !important;
}

.bg-box * {
  /*color: #B9B9B9;*/
  word-break: break-word;
}

.box-bg h2 {
  color: #fff !important;
}

.box-bg h4 {
  margin-top: 0 !important;
  margin-bottom: 0.5rem !important;
}


.faq-main {
  border-bottom: none !important;
  padding: 0 !important;
}

.faq-second-main .faq-content {
  max-width: 840px !important;
  padding-bottom: 60px;
}

.faq-content {
  max-width: 840px;
}

.page-wrap.page-layout.faq-second-main {
  padding-top: 0px !important;
  background: rgba(26,64,55) !important;
}

.faq-main .faqs-body {
  background: none !important;
  max-width: 840px !important;
  margin:0 !important;
}

.accordian-list {
  padding: 0px !important;
}

.accordian-list .faq-header {
  padding: 20px 20px !important;
  border: none !important;
}

.accordian-list .accordian-inner .card {
  border: 3px solid #475551 !important;
  border-radius: 12px;
}

.accordian-list .accordian-inner .card .icon {
}

.card {
  background: none !important;
}

.support-main {
  display: inherit !important;
}

.detail-content ul li {
  padding-bottom: 18px;
}

/* REGISTRATION */

#regForm h3 {
  color: #000 !important;
}

.back-btn {
  padding: 12px !important;
}

.code-send-link {
  margin: 10px !important;
}

.sms-desc {
  margin-left: 0px !important;
}

.register-main-modal .modal-content .modal-header .close {
  right: 20px !important;
  top: 20px !important;
}

.register-main-modal .modal-dialog {
  background: rgba(26,64,55) !important;
  height: auto !important;
  min-height: 100%;
}

.register-main-modal .modal-content {
  /*background: rgba(26,64,55) !important;*/
  background: linear-gradient(rgba(0,0,0,0.36), rgba(26,64,55,0.36)) !important;
  min-height: auto !important;
  box-shadow: none !important;
}

.register-main-modal .modal-content .modal-body {
  background: #fff !important;
  padding: 30px !important;
  margin: 80px auto;
  max-width: 1200px;
  width: calc(100vw * 0.9);
  border-radius: 12px;
}

.register-main-modal .modal-content .modal-body .col-lg-9 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.register-main-modal .modal-content .modal-body .steps {
  overflow-wrap: anywhere;
}


.register-main-modal .modal-content .modal-body .steps .welcome-text {
  font-size: 24px !important;
  font-family: "LatoBold", sans-serif !important;
  line-height: 32px;
  color: #000 !important;
  text-align: left !important;
  padding-bottom: 30px !important;
}

.register-main-modal .modal-content .modal-body .steps .slogan-text {
  font-size: 14px !important;
  color: #000 !important;
  transform: rotate(-12deg);
  background-image: url("http://spielbank.dmcgroup.eu/arrow_slogan.svg") !important;
  background-position: 5% 25% !important;
  background-repeat: no-repeat !important;
  padding-bottom: 70px !important;
  font-style: italic;
  text-align: left !important;
  padding-left: 70px !important;
}

.register-main-modal .modal-content .modal-body .steps .stepper-vertical .is-active .title .step-number {
  background: #16362E !important;
  font-family: "LatoRegular", sans-serif !important;
}

.register-main-modal .modal-content .modal-body .steps .stepper-vertical .is-active .title .step-text {
  color: #16362E !important;
  word-break: break-all;
}

.register-main-modal .modal-content .modal-body .steps .stepper-vertical .title .step-number {
  background: #B9B9B9 !important;
  min-width: 40px;
  font-family: "LatoRegular", sans-serif !important;
  width: 50px !important;
  height: 50px !important;
}

.register-main-modal .modal-content .modal-body .steps .stepper-vertical .title .step-text {
  color: #B9B9B9 !important;
  padding: 10px 0px;
}

.register-main-modal .modal-content .modal-body .steps .info-section {
  background: #F1BF13 !important;
  border-radius: 12px !important;
  color: #000 !important;
}

.register-main-modal .modal-content .modal-body .steps .info-section .amount {
  font-size: 48px !important;
  padding: 10px;
  display: none;
}

.register-main-modal .modal-content .modal-body .steps .info-section .desc {
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  padding: 5px !important;
}

.register-main-modal .modal-content .modal-body .steps .info-section .desc div {
  text-align: center;
}

.form-section-heading {
  color: #000 !important;
  font-size: 24px !important;
  line-height: 32px;
  font-family: "LatoRegular", sans-serif !important;
}

.form-section-sub-heading{
  color: #000 !important;
}

.personal-info .body-inner .custom-label,
.limits-main .body-inner .custom-label {
  color: #000 !important;
}

/* USER ACCOUNT */

.account-main, .user-change-password, .deposit-body-main, .notifications-body, .document-upload, .bonus-main, .limits-main, .self-exclusion-main, .transaction-main, .game-history-main, .limits-history-main {
  margin: 0 !important;
  max-width: 100% !important;
}

.page-wrap.page-layout.account-main,
.page-wrap.page-layout.bonus-main,
.page-wrap.page-layout.limits-main {
  background: none !important;
}

.notifications-body .settings-list,
.notifications-body .settings-list  .settings {
  padding: 0 !important;
}

/* user overview */
.user-overview h2.headline {
  margin-bottom: 48px !important;
}

.user-overview .user-name {
  font-family: "LatoRegular", sans-serif !important;
  font-size: 32px;
  line-height: 40px;
  color: #fff;
  margin-bottom: 20px;
}

.user-overview .btn-theme-outline {
  background: transparent !important;
  font-family: "LatoRegular", sans-serif !important;
}

.user-overview .btn-theme-outline:hover {
  background: #fff !important;
}

.user-overview .deposit-data h4 b:nth-child(2) {
  float: right;
}

.user-overview .deposit-data .text-mute {
  font-size: 13px;
  padding: 0 4px;
  display: inline !important;
}

.user-overview hr {
  display: none;
}

.user-overview h3 {
  border-bottom: 2px solid #fff;
  padding: 14px 0;
}

/*
.set-individual-main div {
border: 3px solid #475551;
border-radius: 12px;
padding: 10px 30px;
margin: 15px 0px;
}

.set-individual-main div div {
border: none;
padding: 0px !important;
}
*/

.set-individual-main .accordion {
  border: 3px solid #4D605B;
  border-radius: 12px;
  padding: 10px 30px;
  margin: 15px 0px;
}

.accordion .card h2 {
  background-size: 14px !important;
}


.set-individual-main .deposit-limit-main {
  padding: 0px !important;
}

.custom-radio .custom-control-label,
.custom-checkbox .custom-control-label {
  color: #000 !important;
  font-family: "LatoRegular", sans-serif !important;
  padding: 2px 4px 18px 15px !important;
}

.row-custom > div.info-note {
  padding: 10px 15px 10px 4rem !important;
}

.limits-main .body-inner {
  padding: 0px !important;
}

.limits-main .body-inner p {
  line-height: inherit !important;
}

.limits-main .body-inner .icon-euro {
  position: absolute;
  left: 1rem !important;
  top: 16px !important;
}

.limits-main .body-inner .set-individual-main div .deposit-limit-main {
  border: none;
  padding: none !important;
}

.limits-main .btn-secondary {
  margin-top: 0px !important;
}

.limit-bt-group {
  /*padding: 20px 0px;*/
  align-items: center !important;
  margin:0 !important;
}

.limit-bt-group .form-group input {
  padding:16px !important;
  padding-left: 34px !important;
  background: #fff !important;
}

.limits-history-main .body-inner .col-lg-6 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
  padding-right: 0px !important;
}

.limits-main .body-inner .col-xl-9 {
  flex: 0 0 100%;
  max-width: 100%;
}

.limits-main .body-inner .icon {
  position: absolute;
  right: 30px;
  color:#fff;
}


.limits-main .body-inner .limit-title-text {
  color:#fff;
}

.user-profile.container {
  padding: 40px 94px 80px !important;
}

.user-profile .layout-nav .nav-sidebar nav .nav-sub .subhead-reg h3 {
  text-transform: uppercase;
  font-family: "brandon-grotesque", sans-serif !important;
  font-size: 40px !important;
  line-height: 48px !important;
}

.user-profile .nav-content {
  padding: 0px !important;
  margin-left: calc(100vw / 30) !important;
  margin-top: 50px;
}

.user-profile .nav-content .row {
  /*padding: 10px 0px !important;*/
}


.user-profile .nav-content h2,
.user-profile .nav-content h2.headline {
  font-size: 32px !important;
  color: #FFFFFF !important;
  line-height: 40px !important;
  text-transform: uppercase !important;
  padding: 0;
  padding-bottom: 20px !important;
  font-family: "brandon-grotesque", sans-serif !important;
}

/* should be h3 */
.user-profile .nav-content .limits-main h2.headline {
  font-size: 24px !important;
  font-family: "LatoRegular", sans-serif !important;
  line-height: 32px !important;
  text-transform: none !important;
  margin-bottom: 24px !important;
  margin-top: 48px !important;
  padding: 0 !important;
}


.user-profile .nav-content .pwd-info.pt-0 {
  border: 1px solid #C82532;
  border-radius: 12px;
  background: #fff;
  color: #C82532 !important;
  font-size:14px !important;
  line-height: 24px !important;
  font-family: "LatoBold", sans-serif !important;
  padding:10px 10px 10px 60px !important;
  background-image: url("http://spielbank.dmcgroup.eu/warning.svg") !important;
  background-position: left calc(1rem) center !important;
  /*background-size: calc(1.5rem) calc(1.5rem) !important;*/
  background-repeat: no-repeat !important;
}


.user-profile .nav-content .pwd-info.pt-0 .text-error,
.user-profile .nav-content .pwd-info.pt-0 .text-valid {
  font-family: "LatoRegular", sans-serif !important;
}

.user-profile .nav-content p {
  color: #B9B9B9 !important;
}

.user-profile .nav-content .body-inner p {
  /*color: #B9B9B9 !important;*/
}

.user-profile .layout-nav .nav-sidebar {
  padding: 10px 20px !important;
  background: #1B4037 !important;
}

.deposit-selection .deposit-option {
  padding: 7px 10px !important;
  font-size: 16px !important;
  text-align: left !important;
  color: #fff !important;
  font-family: "LatoRegular", sans-serif !important;
  background: none !important;
  border-radius: 20px !important;
  border: 2px solid #4D605B !important;
  text-align: center !important;
}

.deposit-selection .deposit-option.active {
  background: #4D605B !important;
  box-shadow: 0 0 0 0 !important;
}

.deposit-body-main .step-body-main h3 {
  margin-bottom: 24px !important;
  margin-top: 48px !important;
}

.deposit-body-main .step-body-main .payment-provider-main .payment-provider {
  background: #fff;
  border: none !important;
}

.deposit-body-main .step-body-main .choose-amount-main{
  grid-template-columns: repeat(3, 1fr) !important;
}

.deposit-body-main .step-body-main .choose-amount-main .amount-inner {
  /*padding: 1rem 1.25rem !important;*/
  padding: 7px 10px !important;
  font-size: 16px !important;
  text-align: left !important;
  color: #fff !important;
  font-family: "LatoRegular", sans-serif !important;
  /*background: none !important;*/
  border-radius: 20px !important;
  border: 2px solid #4D605B !important;
  text-align: center !important;
}

.deposit-body-main .step-body-main .choose-amount-main .amount-inner.inactive {
  background: none !important;
}

.deposit-body-main .step-body-main .choose-amount-main .amount-inner.active {
  background: #4D605B !important;
  box-shadow: 0 0 0 0 !important;
  color: #fff !important;
}

.deposit-body-main .step-body-main .choose-amount-main .amount-inner.active .icon-selected {
  display: none !important;
}

.deposit-body-main .step-body-main .payment-provider-main .payment-provider.is-disabled {
  opacity: 0.5;
  background: grey !important;
}

.deposit-body-main .step-body-main .available-methods .d-flex.align-items-center.justify-content-between {
  background: transparent !important;
  color: #fff;
  border: 2px solid #4D605B !important;
  border-radius: 12px;
  padding: 1.25rem;
  margin: 24px 0;
}

.deposit-body-main .step-body-main .available-methods .amount-text .amount,
.deposit-body-main .step-body-main .available-methods .amount-text .icon {
  position: absolute;
  right: 85px;
  text-align: center;
  width: auto !important;
}

.deposit-body-main .step-body-main .available-methods .amount-text .icon svg {
  width: 50px;
}

.deposit-body-main .step-body-main .choose-amount-main .amount-inner.edit {
  border-radius: 12px !important;
  padding: 1rem 1.25rem !important;
  background: #fff !important;
  text-align: left !important;
  /*
  background-image: url("https://spielbank.dmcgroup.eu/euro.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 3% center !important;
  background-size: 13px !important;
  */
}

.deposit-body-main .step-body-main .choose-amount-main .amount-inner.edit input {
  text-align: left !important;
  background: none !important;
  color: #000 !important;
  height: auto !important;
  padding: 0 !important;
}

.deposit-body-main .step-body-main .choose-amount-main .amount-inner.edit .icon-euro {
  /*display: none;*/
}

.deposit-body-main .step-body-main .available-methods .list-group .list-group-item {
  background-color: #4D605B !important;
  color: #fff;
  border: 0 !important;
  margin: 12px 0px;
  border-radius: 12px;
  padding: 12px 1.25rem !important;
}

.deposit-body-main .step-body-main .available-methods .list-group .list-group-item.pl-0 {
  background-color: transparent !important;
  padding: 0px 0 !important;
  margin: 0 !important;
}

.deposit-body-main .step-body-main .available-methods .list-group .list-group-item .icon-right-arrow svg {
  /*fill: #757575 !important;*/
}


.deposit-body-main .step-body-main .payment-provider-main .payment-provider .min-max,
.deposit-body-main .step-body-main .payment-provider-main .payment-provider .text-pay {
  color:#000 !important;
  font-family: "LatoRegular", sans-serif !important;
}

.deposit-body-main .col-md-6:not(button) {
  max-width: 100% !important;
  flex: 0 0 100% !important;
}

.deposit-body-main .deposit-data {
  padding: 15px 20px 15px !important;
}

.deposit-body-main .step-body-main.col-md-6 .mb-2 {
  border: 2px solid #4D605B !important;
  border-radius: 12px;
  padding: 15px 20px 36px;
  margin: 36px 0 !important;
}

.deposit-body-main .step-body-main button {
  /*float:right;*/
}

.pm-input-field {
  border-radius: 12px !important;
}


.deposit-body-main .deposit-datalist {
  /*border-bottom: 1px solid #ACACAC;*/
  padding: 8px 0 !important;
}

/*
.deposit-body-main .deposit-datalist h5 {
margin-top: -28px;
text-align: right;
}
*/

.deposit-body-main .deposit-datalist p {
  color:#fff !important;
}

.deposit-body-main .deposit-datalist .icon-pay {
  display: none !important;
}

.deposit-body-main .deposit-data h4 {
  color:#fff !important;
  border-bottom: 2px solid #fff;
  padding: 14px 0;
  margin: 0;
}

.deposit-body-main .payout-notification-main .mt-0 {
  background-image: url("https://spielbank.dmcgroup.eu/attention.png") !important;
  background-repeat: no-repeat !important;
  background-position: left center !important;
  background-size: 30px;
  padding-left: 40px;
}

.deposit-body-main .payout-notification-main .icon-pay {
  float: left;
  margin: 4px 0;
}

.deposit-body-main .payout-notification-main .icon-pay svg {
  fill: #F1BF13 !important;
}

.deposit-body-main .payout-notification-main h3 {
  margin-top:0px;
  margin-left:75px;
}

.deposit-body-main .payout-notification-main p {
  margin-left:75px !important;
}

.deposit-body-main .step-body-main .available-methods .amount-text span {
  font-weight: normal !important;
}

.deposit-body-main .step-body-main .available-methods .amount-text span:not(:first-child) {
  padding: 0px 5px !important;
}

.deposit-body-main input {
  font-family: "LatoRegular", sans-serif !important;
  font-size: 18px !important;
}

.deposit-body-main .step-body-main .back-link.mt-5 a,
.deposit-body-main .step-body-main .bonus-content-main .back-link a  {
  text-decoration: none !important;
}

.deposit-body-main .step-body-main .back-link .icon-back-arrow {
  width: 17px !important;
  margin-right: 10px !important;
}

.deposit-body-main .step-body-main .pm-form-label {
  margin-bottom: 1em !important;
}

.verification {
  max-width: 100% !important;
}

.verification .box-bg .verification {
  position: absolute;
  right: 20px !important;
}

.verification .box-bg h3 {
  color: #000 !important;
}

.verification .col-md-7 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.verification .box-bg {
  background: #fff !important;
  margin-bottom: 20px;
  border:none;
}

.verification .box-bg p {
  color: #000 !important;
}

.verification .box-bg img {
  height: 80px !important;
}

.verification .box-bg img.w-50,
.box-bg img.w-50 {
  /*width: 20% !important;*/
  max-width: 180px;
  height: auto !important;
}

.verification .status-icon.verified {
  color: green !important;
}

.verification .last-update-date {
  color: #000 !important;
}

.verification .box-bg .verification .status-icon {
  color: #B9B9B9 !important;
}

.verification .box-bg .verification .status-icon.verified {
  /*width: 80px !important;*/
  color: #478024 !important;
}

.user-account .box-bg {
  background: #e7e7e7 !important;
}

.user-account .box-bg p p {
  color: #000 !important;
}

/* Sidebar */

.user-profile .layout-nav .nav-sidebar nav .nav-sub {
  margin-bottom: 0px !important;
}

.user-profile .layout-nav .nav-sidebar nav .nav-sub h3 {
  color:#ACACAC !important;
}

.user-profile .layout-nav .nav-sidebar nav .nav-sub .subhead-reg {
  color: #fff !important;
  font-size: 40px;
  letter-spacing: 0;
  line-height: 48px;
  background: none !important;
  border: none !important;
  padding: 20px 0 !important;
}

.user-profile .layout-nav .nav-sidebar nav .payment-options {
  display: none;
}

.user-profile .layout-nav .nav-sidebar nav .amount-value {
  display: none;
}

.user-profile .layout-nav .nav-sidebar nav .nav-sub ul{
  background: none !important;
}

.user-profile .layout-nav .nav-sidebar nav .nav-sub ul li a {
  background: none !important;
  color: #ACACAC !important;
  border-top: none !important;
  border-bottom: 2px solid #ACACAC;
  padding-left: 0px !important;
  font-family: "LatoRegular", sans-serif !important;
  text-decoration: none !important;
}

.user-profile .layout-nav .nav-sidebar nav .nav-sub ul li a:hover {
  text-decoration: none !important;
}
.user-profile .layout-nav .nav-sidebar nav .nav-sub ul li a.is-active {
  color: #fff !important;
  border-bottom: 4px solid #fff;
}

/* Einzahlung */
.deposit-body-main .step-body-main .choose-bonus-main h4 {
  /*border: 1px solid #fff !important;
  border-radius: 12px;*/
  display: none;
}

.deposit-body-main .step-body-main .choose-bonus-main .bonus-content-main .bonus-settings .settings-inner {
  border-top: none !important;
  display: block !important;
}

.deposit-body-main .step-body-main .choose-bonus-main .bonus-content-main .bonus-settings .settings-inner .settings-label label {
  color: #fff !important;
}

.deposit-body-main .step-body-main .choose-bonus-main .bonus-content-main .form-group .col-6 {
  text-align: right;
}

.settings-inner .headline label h3,
.settings-inner .headline label {
  font-size: 24px !important;
  text-transform: none !important;
  font-family: "LatoBold", sans-serif !important;
  margin-right: 20px;
}

.deposit-body-main .payout-notification-main {
  padding: 0px !important;
  border: none !important;
}

.deposit-body-main .step-body-main .col-md-8 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

/*
.deposit-body-main .payout-notification-main {
padding: 0px !important;
border:2px solid #C82532 !important;
background: #fff !important;
background-image: url("http://spielbank.dmcgroup.eu/warning.svg") !important;
background-position: left calc(1rem) center !important;
background-repeat: no-repeat !important;
}
*/



.deposit-body-main .bonus-lable {
  color: #fff !important;
  margin: 12px 0px !important;
  display: block;
}

/* TOGGLE */

.switch-body .slider .before {
  height: 26px !important;
  width: 26px !important;
  padding: 0px !important;
  background-size: 55%;
  left: -35px !important;
  /*border: none !important;*/
}

.switch-body input:checked + .slider .before {
  background-size: 55%;
  color: #B58F10 !important;
  border-color: #B58F10 !important;
  left: 0px !important;
}

.switch-body .switch {
  width: 70px !important;
  height: 32px !important;
}

.switch-body .slider .before {
  top: 3px !important;
}




/* Meine Daten */
.user-account {
  margin: 0 0 !important;
}

.user-account .body-inner .custom-checkbox .custom-control-label::before {
  border: #adb5bd solid 1px !important;
}

.user-account .body-inner p {
  color: inherit !important;
}

.user-account .body-inner.row {
  padding-top: 0px !important;
}

.user-profile .nav-content .tab-content .tab-pane {
  padding: 40px 0 !important;
}


.user-profile .nav-content .nav-tabs {
  background: none !important;
  border: none !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  flex-wrap: nowrap !important;
  white-space: nowrap !important;
  overflow-x: auto !important;
  overflow-y: hidden !important;
}

.user-profile .nav-content .nav-tabs .nav-item .nav-link {
  padding: 14px 0px !important;
  margin-right: 30px;
  margin-bottom: 0px !important;
  background: none !important;
  border-bottom: 2px solid #1a4037 !important;
  text-decoration: none !important;
}

.user-profile .nav-content .nav-tabs .nav-item .nav-link.is-active,
.user-profile .nav-content .nav-tabs .nav-item .nav-link.active {
  border-bottom: 4px solid #fff !important;
}

.user-account .accountForm .body-inner .col-md-6 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.user-account .accountForm .box-bg {
  background: #fff !important;
}

.user-account .accountForm .box-bg .status-icon {
  color: #B9B9B9 !important;
}

.user-account .accountForm .box-bg h3 {
  color: #000 !important;
}


/* Bonus */
.bonus-list-main {
  display: block !important;
}

.bonus-list-main .bonus-list {
  background: none !important;
  border: 2px solid #4D605B;
  grid-template-columns: 100% !important;
  border-radius: 12px;
  margin-bottom: 15px !important;
}

.bonus-list-main .bonus-list .bonus-img {
  max-height: 120px;
  min-height: 120px;
  overflow: hidden;
  border-radius: 12px 12px 0px 0px;
  text-align: center;
  font-size: 12px;
  margin-bottom:20px;
}

.bonus-list-main .bonus-list .bonus-img img {
  min-height: 120px;
}

.bonus-list-main .bonus-list .bonus-inner-main {
  display: block !important;
  padding: 40px 32px !important;
}

.bonus-list-main .bonus-list .bonus-inner-main .bonus-content {
  margin-top:-130px;
  max-width: 100% !important;
}

.bonus-list-main .bonus-list .bonus-inner-main .bonus-content h4:first-child {
  margin-bottom: 70px;
  color: #fff;
}

.bonus-main .bonus-body .bonus-data-main .nav-tabs .nav-item .nav-link {
  font-size: 18px !important;
}

.bonus-main .bonus-body .bonus-listing .bonus-money-main .money-list span {
  color: #fff !important;
}

/* Limits */

.user-profile .nav-content .set-individual-main div.first-child {
  border: 1px solid #fff;
  padding: 10px;
  border-radius: 12px;
}

/* Passwort ändern */

#changePasswordForm .row.body-inner {
  padding-top: 0px !important;
}

#changePasswordForm p.pwd-info {
  /*padding-top: 0px !important;*/
  width:99%;
}

#changePasswordForm .row-custom > div {
  /*padding: 0 !important;*/
}


/* Spielerschutz */
.user-profile .nav-content  .col-self-block:nth-of-type(2) {
  margin-top:40px;
}

h1.main-title {
  display: none;
}

/* Spielhistorie */
.col-self-block {
  width:100%;
}


#securityQuestion {
  /*background: none !important;
  color: #fff;*/
}

.info-text {
  margin: 20px 0;
  font-size: 18px !important;
}



/* cookiebot */

#CookiebotWidget .CookiebotWidget-logo svg circle {
  fill: #183f37 !important;
}

#CookiebotWidget {
  right: 10px !important;
  bottom: 130px !important;
  z-index: 999 !important;
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.30) !important;
}

#cookieDeclarationEle {
  /*display: none !important;*/
}

#CybotCookiebotDialog h2 {
  font-family: "LatoBold", sans-serif !important;
  font-weight: inherit !important;
  font-size: 24px !important;
  line-height: 32px !important;
}

#CybotCookiebotDialog *, #CybotCookiebotDialogBodyUnderlay * {
  font-family: "LatoRegular", sans-serif !important;
  font-size: 18px !important;
  letter-spacing: 0px !important;
  line-height: inherit !important;
}

#CybotCookiebotDialog .CybotCookiebotDialogContentWrapper {
  border: 2px solid #fff;
  border-radius: 12px;
}

/* chat */

#launcher {
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.30) !important;
  /*height: 45px !important;*/
  border-radius: 24px;
  margin: 12px 10px !important;
  bottom: 60px !important;
  z-index: 999 !important;
}

.wrapper-3hgZT:focus {
  box-shadow: inset 0 0 0 0 !important;
}

/* OVERSIZE */
@media (min-width: 1921px) {

  .main-swiper-wrap {
    min-height: 633.35px !important;
    max-height: 633.35px !important;
    height: 633.35px !important;

    width: 1920px !important;
    margin: 0 auto;
  }

  .swiper-image {
    background-position-y: top !important;
  }

  .swiper-info .title {
    font-size: 64px !important;
    line-height: 76.8px !important;
  }

  .swiper-info .desc {
    font-size: 32px !important;
    line-height: 42.2333px !important;
    margin: 23.0333px 0px 32px !important;
  }

}

@media (max-width: 1649px) {

  .grid-main-wrap .game-list-menus .container {
    padding: 0px 94px !important;
  }
}

/* OVERSIZE */
/*
@media (min-width: 1441px) {

body {
font-size: 18px !important;
line-height: 24px !important;
}

p {
font-size: 18px !important;
line-height: 24px !important;
}

.grid-header p {
line-height: 32px !important;
}

h1.headline {
font-size: 40px !important;
line-height: 48px !important;
}

h3 {
font-size: 24px !important;
line-height: 32px !important;
}

}
*/

/* DESIGN DEFAULTS */
/*
@media (max-width: 1440px) {

body {
font-size: 18px !important;
line-height: 24px !important;
}

p {
font-size: 18px !important;
line-height: 24px !important;
}

.grid-header p {
line-height: 32px !important;
}

h1.headline {
font-size: 40px !important;
line-height: 48px !important;
}

h3 {
font-size: 24px !important;
line-height: 32px !important;
}
}
*/

@media (min-width: 1200px) {
  .game-detail-main .game-detail-inner .game-detail-right {
    max-width: 830px;
  }

  .games-provider-list .game-provider-body {
    grid-gap: 20px !important;
  }

}

@media (max-width: 1199.98px) {

  .footer .footer-body {
    grid-template-columns: 50% 50% !important;
    text-align: center;
  }

  .games-provider-list .game-provider-body {
    grid-gap: 20px !important;
  }

  .games-provider-list .game-provider-body {
    padding-top: 20px !important;
  }

  .grid-main-wrap .container {
    padding: 0px 50px !important;
  }
  .grid-main-wrap .game-list-menus .container {
    padding: 0px 50px !important;
  }

  .grid-main-wrap .game-category-main .container {
    padding: 0px 50px !important;
  }
  .page-layout .container {
    padding: 30px 50px !important;
  }

  .page-wrap.page-layout.gameplay .game-detail .page-main.container {
    padding: 0px 50px 0px 50px !important;
  }

  .grid-main-wrap .gamesection {
    padding: 0px 50px !important;
  }
  .game-list-menus .menus-main {
    grid-gap: 10px !important;
  }

  .user-profile.container {
    padding: 40px 50px !important;
  }

  .grid-main-wrap .grid-header .headline {
    max-width: 100% !important;
  }

  .title-section {
    max-width: 100% !important;
  }
  .grid-header p {
    margin-left: 0%;
    max-width: 100%;
  }
  .swiper-image.swiper-desktop {
    display: block !important;
  }
  .swiper-image.swiper-mobile {
    display: none !important;
  }
  .page-layout .bg-box {
    padding: 0px !important;
  }
  .main-header.sticky-top {
    height: auto !important;
    margin-bottom: 0px !important;
  }

  .footer .payment-options-main .footer-payment-text {
    padding: 0px 0px !important;
  }

  .game-list-menus .type-scroll-left {
    left:2% !important;
  }
  .game-list-menus .type-scroll-right {
    right:2% !important;
  }

}

@media only screen and (max-width: 1024px)
{
  .main-header .header-item .nav-item a.search-icon {
    display: none !important;
  }


  .swiper-info {
    margin: inherit !important;
    padding: inherit !important;
    height: inherit !important;
    position: relative !important;
    bottom: 0 !important;
  }

  .swiper-info .info {
    flex-direction: inherit !important;
    justify-content: inherit !important;
    align-items: inherit !important;
    justify-content: inherit !important;
    align-items: flex-start;
    background: #fff;
  }

  .swiper-image.swiper-desktop {
    display: block !important;
  }
  .swiper-image.swiper-mobile {
    display: none !important;
  }

}


@media (max-width: 991.98px) {

  /* gameplay mobile nav */
  .gameplay .page-main .full-screen-btn {
    display: none;
  }

  .games-provider-list .page-main.container {
    padding: 0px 30px !important;
  }

  .panic-btn #item {
    width: 20px !important;
    height: 20px !important;
    background-size: 20px 20px !important;
  }

  .panic-btn .track_drag {
    height: 28px !important;
    width: 200px !important;
  }

  .panic-btn .track_text {
    font-size: 16px !important;
  }

  .btn-theme-outline.fav-icon {
    padding: 12px 20px !important;
    width: inherit !important;
    height: 28px !important;
    min-height: 28px !important;
  }

  .gameplay .page-main .fav-icon.favourite-added,
  .gameplay .page-main .fav-icon {
    background-size: 20px;
  }

  .gameplay .page-main .grid-header h2.back-arrow-head .back-header span {
    color: #fff !important;
    height: 28px !important;
  }

  .gameplay .page-main .grid-header h2.back-arrow-head .back-header label {
    font-size: 16px !important;
  }



  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    max-width: 100%;
  }
  .faq-main .faqs-body {
    max-width: 100% !important;
  }

  .title-section {
    max-width: 100% !important;
    display: none;
  }

  .game-list-menus h3 {
    margin-top:0px !important;
  }

  .grid-main-wrap .container {
    padding: 0px 30px !important;
  }
  .grid-main-wrap .game-list-menus .container {
    padding: 0px 30px !important;
  }

  .grid-main-wrap .game-category-main .container {
    padding: 0px 30px !important;
  }
  .page-layout .container {
    padding: 30px 30px !important;
  }

  .user-profile.container {
    padding: 40px 40px 80px !important;
  }

  .page-wrap.page-layout.gameplay .game-detail .page-main.container {
    padding: 0px 15px 0px 15px !important;
  }

  .grid-main-wrap .gamesection {
    padding: 0px 30px !important;
  }

  .main-header .navbar-brand .mobilelogo {
    width: 80px !important;
  }

  .main-header.sticky-top {
    height: auto !important;
    margin-bottom: 0px !important;
  }
  .grid-main-wrap .page-main {
    padding: 30px 50px !important;
  }

  .grid-main-wrap .game-list-menus .container {
    padding: 0px 25px !important;
  }

  /* GAME TILES RESIZING */
  .grid-main-wrap .is-css-grid {
    grid-template-columns: repeat(4, 1fr) !important;
    grid-gap: 20px !important;
    grid-auto-columns: 215px !important;
  }

  .grid-main-wrap .size-1x1 .content {
    height: calc(22vw);
    max-height: 230px;
    max-width: 230px;
  }

  .btn-main {
    padding: 8px 14px !important;
    height: auto !important;
    min-width: inherit !important;
  }
  .btn-theme-outline {
    padding: 8px 14px !important;
    height: auto !important;
    min-width: inherit !important;
  }

  .grid-main-wrap .grid-header .headline {
    /*font-size: 32px !important;
    line-height: 36px !important;*/
    max-width: 100% !important;
  }

  .title-section {
    font-size: 32px !important;
    line-height: 36px !important;
    max-width: 100% !important;
  }

  .footer .container {
    padding: 0px 50px !important;
  }
  .footer .footer-body {
    grid-template-columns: none !important;
    padding: 0px !important;
  }
  .footer .payment-options-main .payment-row .payment-logo-main {
    grid-template-columns: repeat(2, 1fr) !important;
    grid-gap: 20px !important;
  }

  .footer .link-section .footer-menu-head {
  }

  .footer .link-section ul li a {
    padding: 0px 10px !important;
  }
  .section-inner {
    width: 100%;
  }


  .user-profile .layout-nav .nav-sidebar nav {
    background: none !important;
  }

  .limits-main .limits-body .limits-data-main .nav-tabs .nav-item .nav-link {
    font-size: 18px !important;
  }

  .limits-main .body-inner .set-individual-main {
    padding: 0 !important;
  }

  .limit-bt-group {
    max-width: 100%;
  }

  /* registration */

  .register-main-modal .modal-content .modal-body .content .form-section-heading,
  .register-main-modal .modal-content .modal-body .content .form-section-sub-heading {
    text-align: center !important;
  }

  .register-main-modal .modal-content .modal-body {
    margin: 40px auto 120px !important;
    max-width: 100vw !important;
  }

  .register-main-modal .modal-content .modal-body .content div {
    text-align: left !important;
  }

  /* Link button center */
  .register-main-modal .modal-content .modal-body .content .col-lg-12.text-center.mt-4 {
    text-align: center !important;
  }

  .register-main-modal .modal-content .modal-body .content div .text-right {
    text-align: right !important;
  }

  .register-main-modal .modal-content .modal-body .steps .welcome-text {
    display: none;
  }

  .register-main-modal .modal-content .modal-body .steps .slogan-text {
    display: none;
  }

  .register-main-modal .modal-content .modal-body .steps .stepper-vertical ul {
    display: flex;
  }

  .register-main-modal .modal-content .modal-body .steps .stepper-vertical ul li {
    margin: 0px 10px;
  }

  .register-main-modal .modal-content .modal-body .steps .stepper-vertical ul li::after {
    content: "";
    display: block;
    margin-top: 20px;
    margin-left: 0px;
    width: 100%;
    border-bottom: 1px solid #ced4da;
    position: absolute;
    left: 0px;
  }

  .register-main-modal .modal-content .modal-body .steps .stepper-vertical .title {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }


  .register-main-modal .modal-content .modal-body .right-border::before {
    border-right: none !important;
  }

  .register-main-modal .modal-content .modal-body .steps .info-section {
    max-width: unset !important;
  }

  .register-main-modal .modal-content .modal-body .chat-help.desktop-chat {
    display: none !important;
  }

  .register-main-modal .modal-content .modal-body .chat-help.mobile-chat {
    display: flex !important;
    justify-content: center !important;
    margin-top: 0px !important;
  }

  .register-main-modal .modal-content .modal-body .steps .info-section {
    margin: 30px 0px !important;
    max-width: 100% !important;
  }

  .search-body .searchbar-main .search-text {
    display: none;
  }

  .search-body .searchbar-main {
    width: 100%;
  }

  .game-detail-main {
    margin-top: 0px !important;
  }

  .grid-main-wrap .show-on-hover {
    opacity: 1 !important;
    background: linear-gradient(225deg, rgba(0,0,0,0.80) 0%, rgba(0,0,0,0.00) 40%) !important;
  }

  .grid-main-wrap .size-1x1 .content .image::after,
  .grid-main-wrap .size-1x2 .content .image::after,
  .grid-main-wrap .size-2x1 .content .image::after,
  .grid-main-wrap .size-2x2 .content .image::after {
    background: linear-gradient(225deg, rgba(0,0,0,0.80) 0%, rgba(0,0,0,0.00) 40%) !important;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    opacity: 1;
  }

  .grid-main-wrap .grid-header h2.gameplay-header {
    margin: 0 !important;
  }
}


@media (max-width: 991.98px) and (orientation: landscape)
{

}


@media only screen and (max-width: 959px){
  .user-profile .nav-content {
    margin: 0px !important;
  }

}


@media (max-width: 767.98px) {

  h1 {
    font-size: 44px !important;
    line-height: 52px !important;
  }

  h2 {
    font-size: 28px !important;
    line-height: 36px !important;
  }

  .btn-main,
  .btn-theme-outline,
  .btn-primary,
  .btn-secondary {
    font-size: 14px !important;
    line-height: 18px !important;
    width: auto !important;
    max-width: 530px !important;
    height: 40px !important;
  }

  .games-provider-list .game-provider-body .size-1x1 {
    width: 45% !important;
  }

  .games-provider-list .page-main.container {
    padding: 0px 20px !important;
  }

  .games-provider-list {
    padding-top: 10px !important;
    padding-bottom: 30px !important;
  }

  .swiper-image.swiper-desktop {
    display: none !important;
  }
  .swiper-image.swiper-mobile {
    display: block !important;
  }

  .main-swiper-wrap {
    min-height: 100vw !important;
    max-height: 100vw !important;
    height: 100vw !important;
  }

  .swiper-info .info {
    background: none !important;
    max-width: 100% !important;

    /*padding: 80px 40px !important;*/
    /*padding: 12.575% 2.705% 4.575% 2.705% !important;*/

    padding: 14.575% 4.705% 4.575% 4.705% !important;
    margin: 0vw 0px !important;
  }

  .swiper-info .title {
    /*font-size: 24px !important;*/
    /*font-size: 5.8vw !important;*/

    /*font-size: 28px !important;*/
    font-size: 6.765vw !important;

    /*line-height: 27px !important;*/
    /*line-height: 6.525vw !important;*/

    /*line-height: 32px !important;*/
    line-height: 7.73vw !important;

    text-shadow: 1px 1px #000;
    /*word-break: break-word;*/
  }

  .swiper-info .desc {
    /*font-size: 16px !important;*/
    font-size: 3.865vw !important;

    /*line-height: 24px !important;*/
    /*line-height: 5.8vw !important;*/

    /*line-height: 22px !important;*/
    line-height: 5.315vw !important;

    text-shadow: 1px 1px #000;
    word-break: break-word;
  }


  .swiper-info .info .actions {
    position: absolute;
    /*bottom: 4.1vw;*/
    bottom: 45px;
    width: 73vw;
  }

  .swiper-info .tnc-teaser {
    position: absolute;
    bottom: 10px !important;
    left: 5px !important;
    font-size: 13px !important;
    line-height: 15px !important;
    padding-left: 7% !important;
    text-align: left;
    text-shadow: 0 0 4px #000000;
  }

  .swiper-info .info .actions .btn-main {
    width:100%;
  }

  .game-detail-main .game-detail-inner .game-img img {
    max-height: 100% !important;
    width: 100%;
  }

  .game-detailbt-detail {
    flex-direction: row !important;
    margin-bottom: 40px;
  }

  .main-header .header-item .nav-item a.search-icon {
    display: none !important;
  }

  .row-custom > div {
    padding-right: 0px !important;
  }

  .deposit-body-main .step-body-main h3 {
    font-size: 24px !important;
  }

  .deposit-body-main .step-body-main .available-methods .list-group {
    background: none !important;
    border: none !important;
  }

  .choose-bonus-main {
    border: none !important;
  }

  .panic-btn .track_drag {
    margin-left: 32vw !important;
  }

  .grid-main-wrap .page-main {
    padding: 0 20px !important;
  }

  .grid-main-wrap .container {
    padding: 0px 20px !important;
  }

  .page-wrap.page-layout.gameplay .game-detail .page-main.container {
    padding: 0px 20px 0px 20px !important;
  }

  .grid-main-wrap .home-slots-games.gamesection,
  .grid-main-wrap .last-played-games.gamesection,
  .grid-main-wrap .fav-games.gamesection  {
    padding: 0px 20px !important;
  }

  .grid-main-wrap .gamesection {
    padding: 0px 20px !important;
  }

  .grid-main-wrap .game-list-menus .container {
    padding: 0px 20px !important;
  }

  .grid-main-wrap .game-category-main .container {
    padding: 0px 20px !important;
  }
  .page-layout .container {
    padding: 30px 20px !important;
  }

  /* GAME TILES RESIZING */
  .grid-main-wrap .is-css-grid {
    grid-template-columns: repeat(3, 1fr) !important;
    grid-gap: 20px 20px !important;
    grid-auto-columns: 180px !important;
    grid-auto-flow: initial !important;
  }

  .grid-main-wrap .size-1x1 .content {
    height: calc(30vw);
  }

  .all-btn .btn-main .icon {
    color: #000 !important;
  }

  .all-btn .btn-main:hover .icon {
    color: #fff !important;
  }
  .footer .container {
    padding: 0px 25px !important;
  }
  .btn-theme-outline {
    min-height: 34px;
  }

  /* account single row */
  .user-profile .nav-content {
    margin: 0px !important;
  }

  /* register stepper top */
  .register-main-modal .modal-content .modal-body {
    padding: 20px 15px !important;
    width:100% !important;
    height: 100% !important;
    margin: 0 !important;
    border-radius: 0;
  }

  .register-main-modal .modal-content .modal-body .content div {
    text-align: left !important;
  }

  .register-main-modal .modal-content .modal-body .steps .stepper-vertical .title .step-text {
    padding: 10px 0px;
  }

  .form-group .form-control.is-valid + .icon-euro, .form-group .was-validated .form-control:valid + .icon-euro,
  .form-group .form-control.is-invalid + .icon-euro {
    /*right: 14% !important;*/
  }

  .form-group .form-control.is-invalid + .show-hide-password,
  .form-group .form-control.is-valid + .show-hide-password {
    right: 12% !important;
  }

  .form-group.mt-4 {
    width:100%;
  }

  .grid-main-wrap .grid-content.is-scrollable.mobile-scroll {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  /* TABLE */
  table, tbody, thead, tr, th, td {
    display: block;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr td::before {
    position: absolute;
    top: 0px;
    left: 6px;
    width: 45%;
    height: 48px;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-family: "LatoBold", sans-serif;
    padding: 14px 16px !important;
    content: attr(data-title);
    display: block;
  }


  table.table-borderless td {
    border: none;
    position: relative;
    padding-left: 50% !important;
    white-space: normal;
    text-align: left !important;
    padding: 14px 16px;
    padding-left: 16px;
    min-height: 40px !important;
  }


  table.table-striped td {
    border: none;
    position: relative;
    padding-left: 2% !important;
    white-space: normal;
    text-align: left !important;
    padding: 4px 2%;
    min-height: 40px !important;
  }


  tr td.amount {
    text-align: left !important;
  }


  tr td.no-records-msg {
    padding-left: 2% !important;
  }

  tr.odd,
  tr.even {
    border-bottom: 2px solid #4D605B;
  }

  .fav-desktop {
    display: block !important;
  }

  .fav-mobile {
    display: none !important;
  }
  .game-detail-main {
    margin-top: 0px !important;
  }

  .game-detail-main .game-detail-inner .game-detail-right .game-bt-group {
    flex-direction: row !important;
  }

  .anymore-questions {
    background-color: #1a4037 !important;
    margin-top: -70px;
  }

  .deposit-body-main .step-body-main .available-methods .d-flex.align-items-center.justify-content-between {
    padding: 1.25rem 12px;
    margin: 24px 0;
  }

  .deposit-body-main .step-body-main .available-methods .list-group .list-group-item {
    padding: 6px 12px !important;
  }

  .deposit-body-main .step-body-main .available-methods .payment-icon {
    width: 50px !important;
  }

  .user-profile .nav-content h2, .user-profile .nav-content h2.headline {
    padding-top: 0px !important;
  }



}

@media only screen and (max-width: 599px) {

  /*
  .swiper-info .info {
  padding: 80px 50px !important;
}

.swiper-info .info {
align-items: flex-end;
max-width: 100% !important;
padding: 80px 50px !important;
}
*/

}

@media (max-width: 575.98px){


  .grid-main-wrap .gamesection {
    padding: 0px 12px !important;
  }

  .grid-main-wrap .game-category-main .container {
    padding: 0px 12px !important;
  }

  .grid-main-wrap .game-list-menus .container {
    padding: 0px 12px !important;
  }

  .grid-main-wrap .container {
    padding: 0px 12px !important;
  }

  .page-layout .container {
    padding: 30px 12px !important;
  }

  .main-header .header-item .nav-item a {
    min-height: 45px !important;
  }

  .user-profile.container {
    padding: 30px 12px 80px !important;
    margin-top: 0px;
  }

  .footer .container {
    padding: 20px 12px !important;
  }

  .swiper-info .tnc-teaser {
    font-size: 11px !important;
    line-height: 13px !important;
    text-align: center;
    padding: 0 !important;
  }

  /* TEXT */
  body {
    font-size: 16px !important;
    line-height: 22px !important;
  }

  p {
    font-size: 16px !important;
    line-height: 22px !important;
  }

  table.table-custom tr td, table.table-custom tr td p, table.table-custom thead th {
  font-size: 16px !important;
  margin-bottom: 1rem;
}

  h1 {
    font-size: 28px !important;
    line-height: 34px !important;
    padding: 30px 0 !important;
    word-break: break-word;
  }

  /* MVP */
  h1.headline {
    font-size: 28px !important;
    line-height: 34px !important;
    padding: 29px 0 !important;
  }

  .anymore-questions h3 {
    font-size: 28px !important;
    line-height: 34px !important;
  }

  .grid-main-wrap .grid-header h1.headline.with-button,
  .grid-main-wrap .grid-header h2.headline.with-button {
    font-size: 19px !important;
    line-height: 33px !important;
  }

  .grid-header p {
    font-size: 16px !important;
    line-height: 30px !important;
  }

  h2 {
    font-size: 24px !important;
    line-height: 28px !important;
    padding: 40px 0 16px 0 !important;
  }

  .user-profile .nav-content h2, .user-profile .nav-content h2.headline {
    font-size: 24px !important;
    line-height: 28px !important;
    padding: 40px 0 16px 0 !important;
  }

  .user-overview .user-name {
    font-size: 24px;
    line-height: 28px;
  }

  h3 {
    font-size: 19px !important;
    line-height: 25px !important;
    /*margin: 24px 0 8px;*/
    margin: 24px 0 16px;
  }

  /* should be h3 */
  .user-profile .nav-content .limits-main h2.headline {
    font-size: 19px !important;
    line-height: 25px !important;
    margin: 24px 0 16px !important;
  }

  .deposit-body-main .step-body-main h3 {
    font-size: 19px !important;
    line-height: 25px !important;
    margin: 24px 0 16px;
  }

  h4 {
    font-size: 18px !important;
    line-height: 24px !important;
  }

  h5 {
    font-size: 16px !important;
    line-height: 22px !important;
  }

  .games-provider-list .headline .headline-border {
    font-size: 24px !important;
    line-height: 28px !important;
  }

  .text-danger {
    font-size: 11px !important;
  }

  .games-provider-list .game-provider-body {
    padding-top: 0px !important;
  }

  .btn-main,
  .btn-theme-outline,
  .btn-primary,
  .btn-secondary {
    font-size: 14px !important;
    line-height: 18px !important;
    width: 100% !important;
    max-width: 530px !important;
  }

  .btn.btn-block {
    width: 100% !important;
  }

  .swiper-info .info .actions .btn-main {
    font-size: 14px !important;
    line-height: 18px !important;
    height: auto !important;
  }

  .game-detail-main .game-detail-inner .game-detail-right .game-bt-group .game-playbt {
    width: 100%;
  }

  .game-detailbt-detail {
    flex-direction: column !important;
  }

  .main-header .header-item .nav-item .nav-link .count-text {
    display: block !important;
  }

  .bonus-list-main .bonus-list .bonus-inner-main .bonus-content h4:first-child {
    font-size: 20px !important;
  }

  .login-main-modal .modal-content .modal-body {
    padding: 38px 10px !important;
  }

  .row-custom {
    display: flex !important;
  }

  .verification-main .row-custom {
    display: block !important;
  }

  .grid-main-wrap .game-item-btn {
    margin: -16px;
    font-size: 16px;
  }

  .game-list-menus .menus-main {
    display: grid !important;
    grid-auto-flow: initial !important;
    grid-template-columns: repeat(2, 1fr) !important;
    grid-gap: 10px !important;
  }

  .game-list-menus .menus-main .menus-inn .btn {
    font-size: 15px !important;
  }

  .grid-main-wrap .is-css-grid.category-grid {
    display: grid !important;
    grid-auto-flow: initial !important;
    grid-template-columns: repeat(2, 1fr) !important;
    grid-gap: 10px !important;
  }

  .grid-main-wrap .categories .cat-title {
    font-size: 15px !important;
    padding: 10px 10px !important;
  }

  .btn-primary {
    width: 100%;
  }

  .btn-main {
    width: 100%;
  }
  .user-profile .nav-content .nav-tabs .nav-item .nav-link {
    margin-right: 40px;
  }

  .grid-main-wrap .grid-content.is-scrollable.mobile-scroll {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  /* GAME TILES RESIZING */
  .grid-main-wrap .is-css-grid {
    grid-template-columns: repeat(2, 1fr) !important;
    grid-gap: 20px 20px !important;
    grid-auto-columns: 180px !important;
    grid-auto-flow: initial !important;
  }

  .grid-main-wrap .size-1x1 .content {
    height: calc(45vw);
    max-height: 260px;
    max-width: 260px;
  }

  .grid-main-wrap .size-1x2 .content {
    height: calc(39vw);
    max-width: 260px;
  }

  .container .grid-main-wrap .game-items-list .is-css-grid {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .grid-main-wrap .game-custom-grid .size-1x1, .grid-main-wrap .game-custom-grid .size-1x2 {
    max-width: none !important;
  }

  .page-wrap.page-layout.gameplay .game-detail .page-main.container {
    padding-top: 0px !important;
    padding-bottom: 36px !important;
  }

  .form-group .form-control.is-valid + .icon-euro,
  .form-group .was-validated .form-control:valid + .icon-euro,
  .form-group .form-control.is-invalid + .icon-euro {
    /*right: 18% !important;*/
  }

  .row .col-lg-6 .form-group {
    margin-top: 20px !important;
    margin-bottom: 10px !important;
  }

  .form-group > #securityQuestion, .form-group > #accountHolder {
    margin-top: 40px !important;
  }

  .deposit-body-main .deposit-datalist h5 {
    margin-top: 0px;
    text-align: left;
  }

  .footer .payment-row {
    padding: 40px 0px !important;
  }

  .game-history-table tr:nth-child(2n) td {
    background: none !important;
  }

  table tr td {
    background: none !important;
  }

  .bonus-settings .settings-inner .headline {
    margin-left: 0px;
    padding: 10px 0;
  }

  .bonus-settings .d-flex {
    display: block !important;
  }

  .panic-btn .track_drag {
    margin-left: 0% !important;
    margin-top: 65px !important;
    width: 100% !important;
  }

  .panic-btn .track_text {
    margin-left: 32px;
  }

  .back-arrow-head .back-header label{
    display: none;
  }

}

@media (max-width: 413px){

  .main-header .container {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .swiper-info .title {
    font-size: 28px !important;
    line-height: 30px !important;
  }

  .swiper-info .desc {
    font-size: 16px !important;
    line-height: 18px !important;
    margin-top: 4.96667px !important;
    margin-bottom: 6.9px !important;
  }

  .swiper-info .tnc-teaser {
    bottom: 5px !important;
  }
}

@media (max-width: 359px){


  .swiper-info .title {
    font-size: 28px !important;
    line-height: 30px !important;
    word-wrap: break-word;
  }
}