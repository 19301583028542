/*** Main Loader ****/
$main-loader-bg: #154336;

/***** Color Variables ********/
$body-bg-color: #fff;
$white-color: #fff;
$black-color: #000000;
$blue-color: #00316d;
$dark-green: #154336;

$light-blue-color: #0000ff;
$theme-text-color: #154336;
$grey-light-color: #464646;
$box-bg: #154336;
$box-bg-content: #fff;
$reg-default-state: #adadad;

$main-color: #f1bf13;
$success-green-color: #69c905;
$warning-yellow-color: #ffc163;
$error-red-color: #c20000;
$notification-red-color: #e66560;
$border-radius: 14px;
$border-radius-box: 14px;
$border-radius-icons-button: 10px;
$border-radius-button: 14px;
$border-size: 2px;
$border-color: #393939;
$a-tag-color: #ffffff;
$border-radius-cat: 12px;
$box-border-color: #f1bf13;
$header-box-bg: #f1bf13;
$border-radius-input: 6px;
$header-border: #f1bf13;

/**** Modal ****/
$modal-close-btn: #0d2721;
$modal-bg: #fff;
$modal-header-bg: #154336;
$modal-body-text-color: #154336;
$game-detail-box: #e8e8e8;

/**** Main Btn ******/
$btn-main-bg-color: #f1bf13;
$btn-main-border-color: transparent;
$btn-main-text-color: #fff;
$btn-manin-hover-color: #bf940c;
$btn-outline-color: #f1bf13;

/**** Table BG  ****/
$table-header-bg: #154336;
$table-header-color: #fff;
$table-body-bg: #f6f6f6;
$table-body-color: #154336;
$bonus-list-bg: #f6f6f6;

/**** Secondary Btn ******/
$btn-secondary-bg-color: #f1bf13;
$btn-secondary-border-color: transparent;
$btn-secondary-text-color: #fff;
$btn-secondary-hover-color: #c79908;
$btn-secondary-hover-bg: #c79908;
$btn-disabled: #cbd1d2;
$btn-secondary-disabled-bg: #e4bd46;
$btn-secondary-disabled-color: rgb(0 0 0 / 52%);
$btn-theme-hover: #235348;

/*** Main-Header ****/
$header-logo-color: #ffffff;
$main-header-color: #154336;
$header-font-color: #ffffff;
$header-font-color-hover: #ffffff;
$header-item-user-balance: #ffffff;
$green-badges: #154336;
$register-notification-bg: #4c4c4c;

/**** Grid Header Title ***/
$grid-header-headline: #154336;
$layout-nav-bg: #fff;

/***** User Left Nav******/
$active-menu-text-color: #141414;

/**** FAQ *******/
$faq-list-border-color: #d7a93c;

/***** Nav******/
$nav-bottom-bg: #292e35;
$nav-bottom-font-color: #fff;
$nav-active-icon: #292e35;

/*** footer ***/
$footer-bg-color: linear-gradient(90deg, rgba(1, 38, 23, 1) 0%, rgba(23, 70, 58, 1) 100%);
$footer-text-color: #fff;
$footer-bottom-border-color: #333333;
$footer-nav-link-color: #fff;
$footer-payment-bg: #080808;

/**** Tabes *****/
$tabs-bg: #154336;
$tabs-bg-active: #c79908;
$tabs-font-color: #ffffff;

/**** Spacing ****/
$spacing-row: 20px;

/***** Game Badge ****/
$new-game-bg: #f1bf13;
$hot-game-bg: #154336;
