/* You can add global styles to this file, and also import other style files */
.main-title {
    color: #fff;
    padding: 20px 0;
}
.br-default {
    color: #e3131e;
    .br-unit {
        height: 32px;
    }
    .br-selected {
        &:after {
            color: #e3131e;
        }
    }
    .br-active {
        &:after {
            color: #e3131e;
        }
    }
}

.br {
    height: 35px;
    line-height: 35px;
    margin: 0px;
}

.pac-container {
    display: block;
    z-index: 999999 !important;
}

.pac-container:empty {
    display: none !important;
}
