@use "sass:math";
@import "theme";
@font-face {
    font-family: "LatoRegular";
    src: url("../fonts/lato/LatoRegular.eot");
    src: url("../fonts/lato/LatoRegular.eot") format("embedded-opentype"), url("../fonts/lato/LatoRegular.woff2") format("woff2"), url("../fonts/lato/LatoRegular.woff") format("woff"), url("../fonts/lato/LatoRegular.ttf") format("truetype"), url("../fonts/lato/LatoRegular.svg#LatoRegular") format("svg");
}

@font-face {
    font-family: "LatoBold";
    src: url("../fonts/lato/LatoBold.eot");
    src: url("../fonts/lato/LatoBold.eot") format("embedded-opentype"), url("../fonts/lato/LatoBold.woff2") format("woff2"), url("../fonts/lato/LatoBold.woff") format("woff"), url("../fonts/lato/LatoBold.ttf") format("truetype"), url("../fonts/lato/LatoBold.svg#LatoBold") format("svg");
}

@font-face {
    font-family: "Gravesend Sans W05 Fine";
    src: url("../fonts/gravesend/6034451/bbb69900-1129-4e1f-b2b2-d0e9276750ae.woff2") format("woff2"), url("../fonts/gravesend/6034451/eaff4c7a-9421-4027-bd60-2d0511274c59.woff") format("woff");
}

@font-face {
    font-family: "Gravesend Sans W05 Inline";
    src: url("../fonts/gravesend/6034455/498f8eb2-0fd0-44e7-bfbe-2af77c9bc316.woff2") format("woff2"), url("../fonts/gravesend/6034455/35515de6-6200-4d9c-9aa1-2fec6c689006.woff") format("woff");
}

@font-face {
    font-family: "Gravesend Sans W05 Cameo";
    src: url("../fonts/gravesend/6034459/f7370591-c429-4ae1-a57c-582ee23110ea.woff2") format("woff2"), url("../fonts/gravesend/6034459/46f560e8-3a95-4533-a7bf-c9be6d09c3c2.woff") format("woff");
}

@font-face {
    font-family: "Gravesend Sans W05 Light";
    src: url("../fonts/gravesend/6034463/21ff0e0e-0bf2-43df-a217-f46d187d01f5.woff2") format("woff2"), url("../fonts/gravesend/6034463/cb25fab7-568f-474b-b8c1-13ccbac4de38.woff") format("woff");
}

@font-face {
    font-family: "Gravesend Sans W05 Medium";
    src: url("../fonts/gravesend/6034467/6d629c73-df58-4dc5-b426-27daf51caaa5.woff2") format("woff2"), url("../fonts/gravesend/6034467/88a58056-86a0-4f30-891e-2e96c1394776.woff") format("woff");
}

@font-face {
    font-family: "Gravesend Sans W05 Bold";
    src: url("../fonts/gravesend/6034471/71415ac1-cd7a-45d1-a74b-bc2647134e0a.woff2") format("woff2"), url("../fonts/gravesend/6034471/803092d8-3047-4e05-9f4c-686dc3252735.woff") format("woff");
}

@font-face {
    font-family: "Gravesend Sans W05 Heavy";
    src: url("../fonts/gravesend/7615902/a5751223-4ea0-4b53-82f4-391839265d02.woff2") format("woff2"), url("../fonts/gravesend/7615902/3ce2e5a3-597a-48ae-a2e2-6d588f150cf1.woff") format("woff");
}

body {
    color: $theme-text-color;
    background-color: $body-bg-color;
    font-family: "LatoRegular";
    font-style: normal;
    font-weight: normal;
    overflow-x: hidden;
    margin: 0;
    letter-spacing: 0.02em;
    font-size: 1em;
}

// img {
//     max-width: 100%;
// }

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}


/* Firefox */

input[type="number"] {
    -moz-appearance: textfield;
}

a:not([href]):not(.is-disabled):not([disabled]):hover {
    cursor: pointer;
}

a {
    color: $theme-text-color;
    &:hover {
        text-decoration: none;
    }
}

.clear {
    clear: both;
}

.m-0 {
    margin: 0;
}

.m-b-10 {
    margin-bottom: 10px;
}

.m-b-15 {
    margin-bottom: 15px;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-b-21 {
    margin-bottom: 21px;
}

.m-b-30 {
    margin-bottom: 30px;
}

.p-b-15 {
    padding-bottom: 15px !important;
}

.border-bottom-none {
    border-bottom: 0;
}

.flex-wrap-inherit {
    flex-wrap: inherit;
}

p {
    color: $theme-text-color;
    font-size: 1em;
    font-family: "LatoRegular";
}

.text-decoration-none {
    text-decoration: none !important;
}

.btn:focus,
.btn:active,
:focus {
    outline: none !important;
    box-shadow: none !important;
}

.tel-number {
    color: $white-color !important;
}

.w100 {
    width: 100%;
}

.d-block {
    display: block;
}

h1 {
    font-size: 36px;
    font-family: "Gravesend Sans W05 Bold";
    &.headline {
        font-size: 30px;
        // font-family: "LatoBold";;
        color: $dark-green;
        padding: $spacing-row 0;
        margin: 0;
        line-height: 1;
        text-transform: capitalize;
        .headline-border {
            &:after {
                content: "";
                width: 132px;
                height: 5px;
                background: $header-border;
                display: block;
                margin: 10px auto;
            }
        }
        .golden-border {
            border-bottom: 2px solid $box-border-color;
            display: inline;
            padding-bottom: 5px;
        }
        div {
            // font-family: "LatoBold";
        }
        .headline-border {
            &:after {
                margin-top: 10px;
                margin-bottom: 10px;
            }
            &.left-sep {
                &:after {
                    margin: 10px 0;
                }
            }
        }
        &.sm-head {
            font-size: 40px;
            .headline-border {
                &:after {
                    margin: 10px 0px;
                }
            }
        }
        &.lg-head {
            font-size: 80px;
        }
        &.md-head {
            font-size: 60px;
        }
    }
}

h2 {
    font-size: 27px;
    font-family: "Gravesend Sans W05 Bold";
    &.back-arrow-head {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 9;
        margin-top: 8px;
        // margin: 0 !important;
        .back-header {
            width: 48px;
            padding: 17px;
            background: $box-bg;
            border-radius: $border-radius-button;
            color: $white-color;
            margin-right: 10px;
            display: inline-block;
            line-height: 0;
            span {
                color: $white-color;
            }
        }
    }
}

h3 {
    font-size: 21px;
    font-family: "Gravesend Sans W05 Medium";
}

h4 {
    font-size: 18px;
    font-family: "Gravesend Sans W05 Medium";
}

h5 {
    font-size: 15px;
    font-family: "Gravesend Sans W05 Medium";
}

h6 {
    font-size: 12px;
    font-family: "Gravesend Sans W05 Medium";
}

h3 {
    &.title40 {
        font-family: "LatoBold";
        font-size: 40px;
        margin: 20px 0;
    }
    &.grey {
        color: #262626;
    }
    &.green {
        color: #103c2e;
    }
}

.main-nav {
    &.sticky-top {
        z-index: 99999;
    }
}

.padL12 {
    padding-left: 12px;
}

.padR12 {
    padding-right: 12px;
}

.bg-box {
    background: $box-bg;
    padding: 40px;
    border-radius: 10px;
    * {
        word-break: break-all;
    }
    td {
        word-break: keep-all;
    }
    h2 {
        &.headline {
            color: $dark-green;
            word-break: break-all;
        }
    }
}

.footer-pages {
    .bg-box {
        background: $box-bg-content;
        padding: 0;
        p {
            color: $dark-green;
        }
    }
}

.text-danger {
    color: $error-red-color;
    position: absolute;
    bottom: -3px;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-bottom: 0.2rem;
    font-size: 11px;
    width: 100%;
    // display: none !important;
    div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.text-success {
    position: absolute;
    bottom: -3px;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-bottom: 0.2rem;
    font-size: 11px;
}

.input-error-border {
    border-radius: 6px;
    box-shadow: 0 0 0 4px $error-red-color;
}

.tandcClass,
.privacyCls {
    color: $main-color !important;
}

.info-text {
    font-size: 14px !important;
}


/**** Loder ***/

.loader {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    background: $main-loader-bg !important;
    z-index: 10000;
    &.main {
        // background: rgba(0, 0, 0, 0.3) !important;
        background: rgba(0, 0, 0, 0.3) !important;
        .dot {
            background: $black-color !important;
        }
    }
    .loading-screen {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        padding: 20px;
        animation: loading-fade-in 1s;
    }
    .spinner {
        display: flex;
        justify-content: space-around;
        text-align: center;
        width: 45px;
        // margin-bottom: 10px;
        img {
            width: 100%;
            animation: spin 1s linear infinite;
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.container {
    max-width: 1686px;
    padding-left: 16px;
    padding-right: 16px;
}

.page-main {
    color: $black-color;
}

.page-header {
    background-color: $main-header-color;
    padding: 12px 8px;
    .icon {
        height: 37px;
        color: $white-color;
        max-width: 100%;
    }
    h3 {
        font-size: 12px;
        padding-top: 8px;
        font-family: "LatoBold";
        margin: 0;
        color: #adb2b8;
    }
}

.show-hide-password {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0 8px;
    color: $theme-text-color;
    .icon {
        transition: opacity 0.2s ease;
        height: 26px;
        width: 20px;
        svg {
            opacity: 1;
            color: $theme-text-color;
        }
    }
    &:hover,
    &:focus {
        opacity: 1;
        color: $theme-text-color;
    }
}

.modal-title {
    font-size: 18px;
}

.modal-backdrop {
    &.show {
        opacity: 0.8 !important;
    }
}


/****** end Of form Css ****/

#CookiebotWidget {
    .CookiebotWidget-consent-details {
        button {
            display: inherit !important;
        }
    }
}

svg {
    flex: 0 0 100%;
    height: 100%;
    max-width: 100%;
    fill: currentColor;
    overflow: visible;
}


/**** Button Main Green *****/

button {
    cursor: pointer;
}

.btn-primary {
    color: #333;
    background-color: $main-color;
    border: 2px solid $main-color;
    font-family: "LatoBold";
    padding: 9px 40px;
    line-height: 1;
    white-space: nowrap;
    &:hover {
        background-color: #ba930b;
        border-color: #ba930b;
    }
}

.btn-main {
    font-family: "LatoBold";
    color: $btn-main-text-color;
    background: $btn-main-bg-color;
    border-radius: $border-radius-button;
    line-height: 24px;
    padding: 13px 28px;
    border: 2px solid $btn-main-bg-color;
    font-size: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    text-transform: uppercase;
    &.btn-main-md {
        padding: 12px 30px;
        font-size: 1em;
    }
    &.btn-main-xl {
        font-size: 40px;
        padding: 38px 60px;
        border-radius: 50px;
    }
    &.btn-main-md-radius {
        padding: 24px 50px;
        border-radius: 50px;
        font-size: 28px;
    }
    span {
        line-height: 1;
    }
}

.btn-main {
    &:hover {
        color: $btn-main-text-color;
        opacity: 0.9;
    }
    &:focus {
        background-color: $btn-manin-hover-color;
        color: $btn-main-text-color;
    }
}

.btn-main:not(:disabled):not(.disabled).active,
.btn-main:not(:disabled):not(.disabled):active {
    color: $btn-main-text-color;
    background-color: $btn-main-bg-color;
    opacity: 0.4;
}

.btn-main.disabled {
    color: $btn-main-text-color;
    background: $btn-main-bg-color;
    cursor: not-allowed;
    border-color: transparent;
    opacity: 0.4;
}


/**** Outline btn red ****/

.btn-secondary {
    color: $btn-secondary-text-color;
    background: $btn-secondary-bg-color;
    border-radius: $border-radius-button;
    line-height: 24px;
    padding: 22px 50px;
    font-size: 18px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    transition: 0.3s;
    font-family: "LatoBold";
    text-transform: uppercase;
    border: none;
    text-shadow: none;
    border: 2px solid $btn-outline-color;
    .icon {
        width: 14px;
    }
    &.btn-md {
        padding: 12px 30px;
        font-size: 1em;
    }
}

.btn-secondary {
    &:hover {
        color: $btn-secondary-text-color;
        background-color: $btn-secondary-hover-bg;
        border-color: $btn-secondary-hover-bg;
    }
    &:focus {
        background-color: $btn-secondary-hover-bg;
        color: $btn-secondary-text-color;
        border-color: $btn-secondary-hover-bg;
    }
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active {
    color: $btn-secondary-text-color;
    background-color: $btn-secondary-bg-color;
    opacity: 0.4;
}

.btn-secondary:disabled {
    color: $btn-secondary-disabled-color;
    background: $btn-secondary-disabled-bg;
    cursor: not-allowed;
    border-color: transparent;
    opacity: 1;
    box-shadow: none;
}

.btn-sm {
    padding: 0.25rem 1rem;
    font-size: 0.875rem;
    line-height: 1.5;
}


/**** Button Close *******/

.modal-close-bt {
    z-index: 9;
    position: relative;
    color: $white-color;
    background: $modal-close-btn;
    line-height: 1;
    float: right;
    padding: 6px;
    border-radius: 15px;
    height: 40px;
    width: 40px;
    padding: 0 12px;
}

.close {
    opacity: 1 !important;
}


/****** button black ******/

.btn-gray-fill {
    font-family: "LatoBold";
    font-weight: normal;
    font-style: normal;
    color: $white-color;
    align-items: center;
    background: $dark-green;
    padding: 12px 30px;
    font-size: 1em;
    display: flex;
    cursor: pointer;
    border-radius: $border-radius-button;
}

.btn-gray-fill {
    &:hover {
        background: $btn-theme-hover;
        color: $white-color;
        text-decoration: none;
    }
}

.btn-gray-fill {
    .icon {
        // margin-right: 5px;
        line-height: 0;
    }
    .btn-text {
        margin-left: 5px;
    }
}

.btn-theme-outline {
    font-family: "LatoBold";
    font-weight: normal;
    font-style: normal;
    color: $btn-outline-color;
    align-items: center;
    background: transparent;
    padding: 13px 28px;
    font-size: 1em;
    display: flex;
    cursor: pointer;
    border-radius: $border-radius-button;
    border: 2px solid $btn-outline-color;
    text-transform: uppercase;
    &:hover {
        color: $white-color;
        background: $btn-outline-color;
    }
    span {
        line-height: 1;
    }
    &.favourite-added {
        background: $white-color;
        span {
            color: $black-color;
        }
    }
}


/**** Button Square radius outline icon *****/

.bt-square-svg {
    background: $main-color;
    border-radius: 100px;
    border: 0;
    width: 52px;
    line-height: 1;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 8px;
    font-size: 1em;
    text-align: center;
    cursor: pointer;
    span {
        color: $white-color;
        width: 22px;
    }
}


/*** FAQ, ABOUTUS, CONTACT etc. *****/

.title-section {
    color: #fff;
    font-size: 40px;
    max-width: 600px;
}

.page-layout {
    .header {
        padding: 16px;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        color: $white-color;
        background: center/cover no-repeat;
        .title {
            font-family: "LatoBold";
            overflow-wrap: break-word;
            max-width: 100%;
            line-height: 1.2;
            text-transform: uppercase;
            h1 {
                font: inherit;
                margin: 0;
            }
        }
    }
    .page-content {
        padding-top: 16px;
        padding-bottom: 16px;
        .page-content-wrap {
            border: 1px solid #ccc;
            border-radius: 2px;
            background-color: #f5f5f5;
            padding: 16px;
            .btn-warning {
                border: 0px solid transparent;
            }
        }
    }
}

.btn-gray {
    background: $grey-light-color;
}


/***** Modal ****/


/**** Modal OverLayes ****/

.cdk-overlay-container {
    /*z-index: 100000;*/
    z-index: 999999;
}

.mat-mdc-dialog-container {
    padding: 0px !important;
    .modal {
        display: block;
    }
}

.cdk-overlay-container {
    .cdk-overlay-backdrop {
        &.cdk-overlay-backdrop-showing {
            opacity: 0.8;
            background-color: $black-color;
        }
    }
}


/**** Grid Size ******/

.grid-main-wrap {
    background-color: $body-bg-color !important;
    a.game-item-link {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 18px;
        text-align: center;
        font-weight: normal !important;
        color: $white-color !important;
        text-decoration: none;
        line-height: 1.2;
        padding-bottom: 8px;
        height: 29px;
        overflow: visible;
        // padding-top: 4px;
    }
    .gamesection {
        padding: 20px 0;
        max-width: 1507px;
        margin: 0 auto;
    }
    .page-main {
        color: $white-color;
    }
    .grid-header {
        // margin-bottom: 20px;
        .headline {
            color: $grid-header-headline;
            text-transform: uppercase;
        }
        .header-btn {
            margin-left: 10px;
            .btn {
                padding: 10px;
                border-radius: 12px;
                .icon {
                    height: 16px;
                    line-height: 16px;
                }
            }
        }
        // .btn-main {
        //     padding: 13px 40px;
        //     border-radius: 50px;
        //     font-size: 20px;
        // }
        .btn-gray {
            padding: 8px 16px;
        }
    }
    .grid-content {
        &.is-scrollable {
            &.categories {
                padding-top: 30px;
                .game-items-list {
                    max-width: inherit;
                }
            }
        }
        &.gallery-hover-main {
            margin-bottom: 0;
            position: relative;
            overflow-x: inherit !important;
            overflow-y: inherit !important;
        }
        // margin-bottom: 20px;
        &.is-scrollable {
            overflow-x: auto;
            overflow-y: hidden;
            -webkit-overflow-scrolling: touch;
             ::-webkit-scrollbar {
                display: none;
            }
            .game-items-list {
                position: relative;
                margin: 0 auto;
                &.modal-gallery {
                    .gallery {
                        padding-top: $spacing-row;
                        padding-bottom: $spacing-row;
                    }
                }
                // min-width: 1200px;
                .btn-main {
                    .icon {
                        svg {
                            height: 46px;
                        }
                    }
                }
                #scroll-left {
                    position: absolute;
                    top: 34%;
                    z-index: 1;
                    left: -110px;
                    background: transparent;
                }
                #scroll-right {
                    position: absolute;
                    top: 34%;
                    // z-index: 10000;
                    right: -110px;
                    background: transparent;
                }
                .gallery {
                    .icon {
                        line-height: 1;
                    }
                }
            }
        }
    }
    .is-css-grid {
        display: grid;
        grid-auto-flow: dense;
        grid-auto-rows: 1fr;
        grid-gap: 27px;
        &.category-grid {
            .size-1x1 {
                border-radius: $border-radius-cat;
                overflow: hidden;
                padding: 25px;
                background-repeat: no-repeat;
                background-position: bottom right;
                background: $main-color;
                transition: 0.2s;
                border: 2px solid $box-border-color;
                align-items: center;
                justify-content: center;
                .game-item {
                    height: 100%;
                    min-height: 100%;
                    .is-1x1 {
                        .is-1x1 {
                            position: absolute;
                            width: 83px;
                            height: 83px;
                            right: -15px;
                            bottom: -5px;
                            transform: rotate(15deg);
                        }
                    }
                }
                &.show-all-cat {
                    background: $black-color;
                }
            }
            &.has-max-columns-4 {
                // grid-template-columns: repeat(auto-fill, minmax(366px, 1fr));
                grid-auto-flow: column;
                grid-auto-columns: minmax(366px, 1fr);
                overflow-x: auto;
                -ms-overflow-style: none;
                scrollbar-width: none;
                scroll-behavior: smooth;
                margin-top: $spacing-row;
                margin-bottom: $spacing-row;
                grid-gap: 60px;
            }
            &.no-scroll {
                grid-auto-flow: initial !important;
            }
        }
        &.has-max-columns-8 {
            grid-template-columns: repeat(auto-fill, minmax(161px, 1fr));
            grid-auto-flow: column;
            grid-auto-columns: minmax(160px, 1fr);
            overflow-x: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            scroll-behavior: smooth;
        }
        &.has-max-columns-5 {
            // grid-auto-flow: column;
            grid-template-columns: repeat(5, 1fr);
            grid-auto-columns: minmax(272px, 1fr);
            overflow-x: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            scroll-behavior: smooth;
            grid-auto-columns: 273px;
        }
        &.has-max-columns-6 {
            grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
            grid-auto-flow: column;
            grid-auto-columns: minmax(230px, 1fr);
            overflow-x: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            scroll-behavior: smooth;
        }
        &.has-max-columns-7 {
            // grid-template-columns: repeat(auto-fill, minmax(193px, 1fr));
            // grid-auto-flow: column;
            grid-template-columns: repeat(7, 1fr) !important;
            grid-auto-columns: minmax(193px, 1fr);
            overflow-x: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            scroll-behavior: smooth;
            grid-auto-columns: 193px;
        }
        &.no-scroll {
            overflow: initial;
            .size-1x1,
            .size-2x1,
            .size-1x2,
            .size-2x2 {
                position: relative !important;
            }
            .size-2x1 {
                &::before {
                    padding-bottom: 0;
                }
            }
            &.has-max-columns-2 {
                grid-template-columns: repeat(2, 1fr);
            }
            &.has-max-columns-3 {
                grid-template-columns: repeat(3, 1fr);
            }
            &.has-max-columns-4 {
                grid-template-columns: repeat(4, 1fr);
                margin: $spacing-row 0;
            }
            &.has-max-columns-5 {
                grid-template-columns: repeat(5, 1fr);
                grid-auto-flow: initial;
            }
            &.has-max-columns-6 {
                grid-template-columns: repeat(6, 1fr);
                grid-auto-flow: initial;
            }
            &.has-max-columns-7 {
                grid-template-columns: repeat(7, 1fr);
                grid-auto-flow: initial;
            }
            &.has-max-columns-8 {
                grid-template-columns: repeat(8, 1fr);
            }
            &.has-max-columns-9 {
                grid-template-columns: repeat(9, 1fr);
            }
            &.has-max-columns-10 {
                grid-template-columns: repeat(10, 1fr);
            }
            &.has-max-columns- {
                grid-template-columns: repeat(6, 1fr);
            }
        }
    }
    .categories {
        .cat-title {
            font-family: "LatoBold";
            font-size: 26px;
            text-transform: uppercase;
            text-align: center;
            line-height: 1.1;
        }
        .cat-sub-title {
            opacity: 0.7;
        }
        .icon {
            width: 20px;
            margin: 0 auto;
            color: #828282;
        }
        .show-all-cat {
            .image {
                // width: 184px;
                height: 100%;
                background: #222222;
                border-radius: $border-radius-box;
                display: block;
                width: 100%;
                position: absolute;
            }
            .icon {
                img {
                    height: 15px;
                }
            }
        }
    }
    .size-1x2 {
        grid-row: span 2 / auto;
    }
    .size-2x1 {
        grid-column: span 2 / auto;
    }
    .size-2x2 {
        grid-column: span 2;
        grid-row: span 2;
    }
    .size-1x1,
    .size-1x2,
    .size-2x1,
    .size-2x2 {
        position: relative;
        display: block;
        min-height: auto;
        display: flex;
        flex-direction: column;
        .content {
            flex: 1 1 auto;
            min-height: 273px;
            .image {
                height: 100%;
                position: relative;
                z-index: 11;
                display: block;
                // box-shadow: -1px 24px 22px -13px rgba(0, 0, 0, 0.55);
                // -webkit-box-shadow: -1px 24px 22px -13px rgba(0, 0, 0, 0.55);
                // -moz-box-shadow: -1px 24px 22px -13px rgba(0, 0, 0, 0.55);
            }
        }
    }
    .size-2x1 {
        &::before {
            padding-bottom: 0%;
        }
    }
    .game {
        height: 100%;
        cursor: pointer;
        outline: 0;
        color: #fff;
        // display: flex;
        align-items: center;
        &.has-hover {
            position: relative;
            border-radius: $border-radius;
            overflow: hidden;
        }
    }
    .image {
        position: relative;
        img {
            position: relative;
            width: 100%;
            height: 100%;
            border: 0;
            position: relative;
            border-radius: $border-radius;
        }
        &::after {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            display: block;
            top: 0;
            left: 0;
            opacity: 0;
        }
    }
    .is-1x1,
    .is-1x2,
    .is-2x1 {
        .show-on-hover {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
            border-radius: $border-radius;
            overflow: hidden;
        }
    }
    .game-item-btn {
        // margin: -16px;
        background: $dark-green;
        display: grid;
        grid-template-columns: auto auto;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0 !important;
        z-index: 99;
        .item-btn-inn {
            padding: 10px;
            text-align: center;
            text-decoration: none;
            border-right: 2px solid rgba(255, 255, 255, 0.1);
            text-decoration: none !important;
            &:last-child {
                border-right: 0;
            }
            &.trynow {
                opacity: 0.7;
            }
            &:hover {
                opacity: 0.4;
                color: #000 !important;
            }
        }
    }
    .show-on-hover {
        // position: absolute;
        // top: 0;
        // bottom: 0;
        // left: 0;
        // right: 0;
        // height: 100%;
        // width: 100%;
        opacity: 0;
        // transition: 0.2s ease;
    }
    .hover-image.is-1x1 {
        background: top/contain no-repeat;
    }
    .has-hover {
        &:hover {
            .image {
                &::after {
                    opacity: 1;
                }
            }
            .show-on-hover {
                opacity: 1;
                padding-left: 16px;
                padding-right: 16px;
                z-index: 2;
                background-size: cover;
                background-position: center center;
            }
        }
    }
    .has-hover:hover .show-on-hover+.hide-on-hover {
        opacity: 0;
    }
    .game-info {
        // position: absolute;
        // top: 0;
        // right: 0;
        // bottom: 0;
        // left: 0;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // padding: 16px;
        // justify-content: space-between;
        .game-det {
            position: absolute;
            right: 10px;
            top: 10px;
            z-index: 99;
            // width: 100%;
            // text-align: right;
            span,
            img {
                display: block;
                width: 24px;
                float: right;
                line-height: 1;
            }
        }
        button {
            margin: 0 auto;
        }
        a.game-demo-link {
            font-weight: bold;
            font-size: 1em;
            color: $main-color;
        }
        &.with-content {
            padding-right: 20px !important;
            p:nth-child(1) {
                font-weight: normal;
                font-size: 18px;
            }
            p:nth-child(2) {
                font-weight: normal;
                font-size: 1em;
            }
            .btn {
                width: auto;
                margin: 0px auto;
            }
        }
        .demo-game-link {
            min-height: 24px;
            text-align: center;
        }
    }
    .game.is-1x1 .game-info,
    .game.is-2x2 .game-info {
        justify-content: space-between;
    }
    // .game.is-2x1 .game-info {
    //     padding-left: calc(20% + 20px);
    //     padding-right: calc(20% + 20px);
    // }
    .game-title {
        margin-top: 10px;
        min-height: 18px;
        text-align: left;
        height: 24px;
        a {
            text-decoration: none;
            font-size: 1em;
            color: $dark-green;
            font-family: "LatoBold";
            text-transform: uppercase;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        .icon {
            padding-left: 5px;
            display: none;
            svg {
                width: 11px;
                fill: $theme-text-color;
            }
        }
        &.game-title-v {
            min-height: 18px;
            bottom: -28px;
            position: absolute;
            width: 100%;
        }
    }
    .demo-btn {
        height: 20px;
        display: block;
    }
    .game-category-main {
        background-repeat: no-repeat;
        background-position: bottom left;
        padding-bottom: 40px;
        box-shadow: -2px 7px 15px 0 rgb(0 0 0 / 11%);
    }
    .home-slots-games {
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: cover;
    }
}

.all-btn {
    .btn-main {
        &:hover {
            .icon {
                filter: invert(1);
            }
        }
        .icon {
            display: none;
        }
    }
}


/* Game NEW HOT Label */

.game-hot-label-badge {
    background: #fa2200;
    padding: 6px 8px;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    font-family: "LatoBold";
    font-size: 14px;
    .icon {
        width: 13px;
        margin-right: 5px;
    }
}

.game-badges {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    pointer-events: none;
    -webkit-user-select: none;
    user-select: none;
    transform: translate3d(0, 0, 0);
    .badge-position {
        position: absolute;
        z-index: 1;
        left: 10px;
        top: 10px;
        display: flex;
        grid-gap: 8px;
        align-items: center;
    }
    .game-badge {
        border-radius: 6px;
        font-size: 15px;
        text-align: center;
        padding: 3px;
        width: 70px;
        color: $white-color;
        &.new {
            background: $new-game-bg;
        }
        &.hot {
            background: $hot-game-bg;
        }
    }
}

.badge-hover {
    position: absolute;
    left: 10px;
    top: 10px;
}

.game-theme-badge {
    background: $main-color;
    font-size: 12px;
    padding: 6px 8px;
    border-radius: 8px;
    display: inline;
    float: left;
    line-height: 1;
    margin-right: 8px;
    white-space: nowrap;
}

b,
strong {
    font-family: "LatoBold";
    font-weight: normal;
}

.bold {
    font-family: "LatoBold";
}

.cookies-accept {
    background: $white-color !important;
}

.MsoNormalTable {
    width: auto !important;
    tr {
        td {
            padding-left: 8px;
            padding-right: 8px;
            border: 1px solid #424242;
        }
    }
}

.icon-right-arrow {
    width: 14px;
    margin-left: 10px;
    display: inline-block;
}

p {
    font-size: 1em;
    a {
        color: $main-color !important;
        text-decoration: underline !important;
        &:hover {
            text-decoration: none !important;
        }
    }
    &.sm-font {
        font-size: 20px;
    }
    &.xs-font {
        font-size: 18px;
    }
    &.paragraph {
        font-size: 25px;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.icon {
    min-width: 14px;
    display: inline-block;
}

.box-bg {
    background: $box-bg;
    border-radius: $border-radius-box;
    padding: 30px;
    height: 100%;
    p {
        color: $white-color;
    }
}

.headsm {
    color: $white-color;
    font-size: 20px;
    margin-bottom: 10px;
}

.headxl {
    font-size: 36px;
    color: $white-color;
    margin-bottom: 20px;
    font-family: "LatoBold";
}

.section-inner {
    padding: $spacing-row 0;
}


/***** Custom Switch ******/

.switch-body {
    .switch {
        position: relative;
        display: inline-block;
        width: 90px;
        height: 48px;
        margin-bottom: 0;
        input {
            display: none;
        }
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #848585;
        transition: 0.4s;
        border-radius: 40px;
        .before {
            position: absolute;
            // content: "OFF";
            height: 35px;
            width: 35px;
            top: 6px;
            left: -32px;
            right: 1px;
            bottom: 1px;
            transition: 0.4s;
            border-radius: 40px;
            background: $white-color url("../images/switch-off.svg");
            background-repeat: no-repeat;
            background-position: center center;
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(40px);
            padding: 2px;
            text-indent: -20000px;
            background-size: 16px;
            border: 2px solid #6b6b6b;
        }
        .after {
            // content: "ON";
            color: $white-color;
            position: absolute;
            transform: translate(-10px, -50%);
            top: 47%;
            font-size: 13px;
            left: 28%;
            // background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3C!-- Generator: Adobe Illustrator 22.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 12 10' style='enable-background:new 0 0 12 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0{fill:%23FFFFFF;} %3C/style%3E%3Cpath class='st0' d='M11.8,0.2c-0.2-0.3-0.6-0.3-0.8,0L3.8,8.4L1,5.2C0.8,5,0.4,5,0.2,5.2c-0.2,0.3-0.2,0.7,0,1l3.2,3.6 c0.2,0.3,0.6,0.3,0.8,0l7.6-8.6C12.1,0.9,12.1,0.5,11.8,0.2z'/%3E%3C/svg%3E");
            display: block;
            background-repeat: no-repeat;
            text-indent: -20000px;
        }
    }
    input {
        &:checked {
            + {
                .slider {
                    background: $main-color;
                    .before {
                        position: absolute;
                        content: "ON";
                        height: 38px;
                        width: 38px;
                        top: 5px;
                        left: 6px;
                        right: auto;
                        bottom: 1px;
                        transition: 0.4s;
                        border-radius: 40px;
                        background: $white-color url("../images/switch-on.svg");
                        background-repeat: no-repeat;
                        background-size: 18px;
                        background-position: center center;
                        color: $main-color;
                        border: 2px solid #d19f13;
                    }
                    background-color: $main-color;
                    .after {
                        content: "OFF";
                        color: $white-color;
                        display: block;
                        position: absolute;
                        transform: translate(0px, -8px);
                        top: 47%;
                        left: 62%;
                        font-size: 18px;
                        // background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3C!-- Generator: Adobe Illustrator 22.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0{fill:%23FFFFFF;} %3C/style%3E%3Cpath class='st0' d='M5.9,5l3.9-3.9c0.3-0.3,0.3-0.7,0-0.9c-0.3-0.3-0.7-0.3-0.9,0L5,4.1L1.1,0.2c-0.3-0.3-0.7-0.3-0.9,0 c-0.3,0.3-0.3,0.7,0,0.9L4.1,5L0.2,8.9c-0.3,0.3-0.3,0.7,0,0.9C0.3,9.9,0.5,10,0.6,10S1,9.9,1.1,9.8L5,5.9l3.9,3.9 C9,9.9,9.2,10,9.4,10c0.2,0,0.3-0.1,0.5-0.2c0.3-0.3,0.3-0.7,0-0.9L5.9,5z'/%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                    }
                }
            }
        }
        &:focus {
            + {
                .slider {
                    box-shadow: 0 0 1px #2196f3;
                }
            }
        }
    }
}


/****** Modal CSS *******/

.modal-lg-custom {
    overflow-y: auto;
    overflow-x: hidden;
    .modal-dialog {
        max-width: 800px;
    }
}


/**** has label ****/

.form-group {
    // margin-bottom: 5px;
    position: relative;
    &.custom {
        label {
            display: flex;
            align-items: center;
            visibility: hidden;
            opacity: 0;
            transition-property: visibility, opacity;
            transition-duration: 0.2s;
            transition-timing-function: ease;
            position: absolute;
            left: 10px;
            color: #666666;
            font-size: 11px;
            font-family: "LatoBold";
        }
        &.has-label {
            label {
                visibility: visible;
                opacity: 1;
            }
        }
    }
    .form-control {
        border-radius: $border-radius-input;
        min-height: 48px;
    }
    .form-control.is-valid,
    .was-validated .form-control:valid {
        background-position: right calc(0.775em + 0.1875rem) center;
        +.icon-euro {
            right: 40px !important;
        }
    }
    input,
    select {
        border-radius: $border-radius-input;
        height: 48px;
        font-family: "LatoBold";
        color: $black-color;
    }
    .custom-select {
        box-shadow: none;
        display: inline-block;
        width: 100%;
        height: 48px;
        padding: 0 16px;
        font-size: 1em;
        line-height: 1.5;
        color: $white-color;
        vertical-align: middle;
        border: none;
        color: #696969;
        border: 1px solid #ddd;
    }
    .password-main {
        display: flex;
        position: relative;
    }
    .form-control {
        &.is-invalid {
            background: #f9e5e5 url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 22.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 100 100' style='enable-background:new 0 0 100 100;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23C20000;%7D%0A%3C/style%3E%3Cpath class='st0' d='M50,0C22.4,0,0,22.4,0,50c0,27.6,22.4,50,50,50s50-22.4,50-50C100,22.4,77.6,0,50,0z M54.3,73.4h-9.5V65h9.5 V73.4z M54.3,57h-9.5V25.9h9.5V57z'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: right calc(0.375em + 0.1875rem) center;
            background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
            +.show-hide-password {
                right: 30px;
            }
            +.icon-euro {
                right: 35px !important;
            }
        }
        &.is-valid {
            +.show-hide-password {
                right: 30px;
            }
        }
    }
}

.group-input {
    .form-group {
        margin-bottom: 0 !important;
        input,
        select {
            border: 0;
            border-radius: 0;
            border-bottom: 1px solid #7e7e7e;
        }
        &:first-child {
            input,
            select {
                border-top-left-radius: $border-radius;
                border-top-right-radius: $border-radius;
            }
        }
        &:last-child {
            input,
            select {
                border-bottom-left-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
                border-bottom: 0;
            }
        }
    }
}

.count-notify {
    color: $main-color;
    padding: 4px 0;
    line-height: 1;
    border-radius: 50px;
    font-family: "LatoBold";
    font-size: 12px;
    width: 40px;
    text-align: center;
    display: block;
    margin: 0 auto;
}

.toast-container {
    .ngx-toastr {
        padding: 15px 15px 15px 15px;
        &.toast-success,
        &.toast-error,
        &.toast-info,
        &.toast-warning {
            background-image: none;
        }
        &.toast-success {
            background-color: #29861b;
        }
        &.toast-error {
            background-color: #c30000;
        }
    }
}

.paginate {
    ul {
        padding: 0 !important;
        float: right !important;
        li {
            a {
                color: $dark-green;
                &:hover {
                    background: $main-color;
                    color: $white-color !important;
                    border-radius: 6px;
                }
            }
            &.current {
                background: $main-color;
            }
            border-radius: 6px;
        }
    }
}

.no-data-msg {
    background: #f8f7f6;
    border-radius: 10px;
    padding: 15px 20px;
    font-size: 18px;
    margin-bottom: 20px;
    color: $dark-green;
    border: 4px solid $main-color;
}

.row-custom {
    margin-left: -10px;
    margin-right: -10px;
    >div {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.game-rating {
    margin-bottom: 10px;
    .stars {
        ngx-stars {
            .stars-line {
                div {
                    display: flex;
                    [class^="star ngx-stars-star-empty-"] {
                        background-color: #ddd !important;
                    }
                    .star {
                        width: 23px !important;
                        height: 23px !important;
                        background-color: $main-color !important;
                    }
                }
            }
        }
    }
}

.placeholder {
    &.hide-on-focus:focus {
        &::-webkit-input-placeholder {
            color: transparent;
        }
        &:-moz-placeholder {
            color: transparent;
        }
        &::-moz-placeholder {
            color: transparent;
        }
        &:-ms-input-placeholder {
            color: transparent;
        }
    }
}

:not(.has-labe) {
    .is-invalid {
        &::-webkit-input-placeholder {
            color: $error-red-color;
        }
        &:-moz-placeholder {
            color: $error-red-color;
        }
        &::-moz-placeholder {
            color: $error-red-color;
        }
        &:-ms-input-placeholder {
            color: $error-red-color;
        }
        &.hide-on-focus:focus {
            &::-webkit-input-placeholder {
                color: transparent;
            }
            &:-moz-placeholder {
                color: transparent;
            }
            &::-moz-placeholder {
                color: transparent;
            }
            &:-ms-input-placeholder {
                color: transparent;
            }
        }
    }
    select {
        &.is-invalid {
            color: $error-red-color;
            option {
                color: $black-color;
                background: $white-color;
            }
        }
    }
}

.ft-terms {
    p {
        margin: 0px;
        color: $modal-body-text-color;
    }
}

.cc-window {
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    max-width: 100% !important;
    flex-direction: row !important;
    .cc-message {
        font-size: 1em;
        margin: 16px !important;
        font-family: "LatoRegular";
    }
    .cc-compliance {
        margin-left: 16px;
        margin-right: 16px;
        .cc-btn {
            &.cc-allow {
                background: #fff !important;
                color: #000 !important;
                border-radius: 6px !important;
                font-family: "LatoRegular";
                text-transform: uppercase;
                line-height: 24px;
                padding: 8px;
                font-size: 1em;
                white-space: nowrap;
            }
        }
    }
}


/* Google Map */

.poi-info-window div,
.poi-info-window a {
    background-color: $box-bg;
    color: $white-color;
}

.agm-map-container-inner {
    border-radius: 10px;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
    background: $box-bg;
}

.info-window {
    color: #fff;
    .name {
        h4 {
            font-family: "LatoBold";
            font-size: 20px;
        }
    }
    .title {
        h5 {
            font-family: "LatoBold";
            font-size: 1em;
        }
    }
    .address {
        color: #7f7f7f;
        font-size: 15px;
    }
    .time {
        span {
            font-family: "LatoBold";
            color: #69c905;
        }
    }
}

.gm-ui-hover-effect {
    img {
        filter: invert(1);
    }
}

.main-swiper-wrap {
    .swiper-container-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
        bottom: 20px;
        display: none;
    }
    .swiper-pagination-bullet {
        background: transparent;
        border: 3px solid #fff;
        width: 12px;
        height: 12px;
        opacity: 1;
    }
    .swiper-pagination-bullet-active {
        background: #fff;
    }
}

.seprater {
    display: grid;
    width: 100%;
    justify-content: space-between;
    grid-template-columns: 1fr 276px 1fr;
    align-items: center;
    position: relative;
    top: -22px;
    z-index: 9;
    .border-sep {
        height: 5px;
        background: linear-gradient( 90deg, rgba(182, 114, 57, 1) 0%, rgba(252, 238, 174, 1) 22.75%, rgba(216, 163, 91, 1) 52.16%, rgba(216, 163, 91, 1) 75.29%, rgba(241, 214, 147, 1) 82.75%, rgba(225, 189, 124, 1) 92.16%, rgba(195, 138, 79, 1) 100%);
        width: calc(100% + 5px);
        &.last-border {
            position: relative;
            left: -5px;
        }
    }
    .seprater-img {
        position: relative;
        z-index: 9;
    }
}

.game-sep-bg {
    img {
        width: 100%;
    }
}

.home-top-sep {
    z-index: 99;
}

.hover-image.is-1x1 {
    background: top/contain no-repeat;
}

.about-body {
    .about-main {
        .number-list {
            font-size: 25px;
            line-height: 2;
            list-style-type: auto;
        }
        .content-title {
            font-family: "LatoBold";
            font-size: 40px;
            margin-top: 30px;
        }
    }
}

.aboutpage {
    .accordian-list {
        .accordian-inner {
            .card {
                h3 {
                    font-family: "LatoBold";
                    font-size: 40px;
                }
            }
        }
    }
}

.footer {
    .footer-body {
        .about-detail-content {
            &.service-text {
                .footer-address {
                    b {
                        font-family: "LatoRegular";
                    }
                }
            }
        }
    }
    .link-section {
        .about-logo {
            .about-item {
                .icon {
                    width: 100% !important;
                }
            }
        }
    }
}


/* Mobile Media */

.switch-text {
    span {
        line-height: 1;
        font-family: "LatoBold";
    }
}

.form-group {
    .form-control {
        &.euro-input {
            padding-left: 35px !important;
        }
    }
}

// Extra large devices (large desktops, 1600px and up)
@media (min-width: 1600px) {
    h2 {
        &.back-arrow-head {
            .back-header {
                position: absolute;
                left: -60px;
            }
        }
    }
}

@media (max-width: 1649px) {
    .grid-main-wrap {
        .grid-content {
            &.is-scrollable {
                .game-items-list {
                    #scroll-left {
                        left: 0;
                    }
                    #scroll-right {
                        right: 0;
                    }
                }
            }
        }
        .size-1x1,
        .size-1x2,
        .size-2x1,
        .size-2x2 {
            .content {
                min-height: 100px;
            }
        }
    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
    .seprater {
        top: -11px;
    }
    .game-badges {
        .game-badge {
            // padding: 2px;
            width: 60px;
            // border-radius: 5px;
            font-size: 13px;
        }
    }
    .seprater {
        grid-template-columns: 1fr 140px 1fr;
        .border-sep {
            height: 2px;
        }
    }
    .grid-main-wrap {
        .is-css-grid {
            grid-gap: 20px;
            &.category-grid {
                &.has-max-columns-4 {
                    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
                    grid-auto-columns: minmax(280px, 1fr);
                    grid-gap: 20px;
                }
            }
            &.has-max-columns-5 {
                grid-auto-columns: minmax(210px, 1fr);
                grid-auto-columns: 210px;
            }
            &.has-max-columns-7 {
                grid-template-columns: repeat(5, 1fr) !important;
            }
        }
        .home-slots-games {
            background-size: 140%;
        }
    }
    .grid-main-wrap {
        .grid-content {
            &.is-scrollable {
                .game-items-list {
                    #scroll-right {
                        display: none;
                    }
                    #scroll-left {
                        display: none;
                    }
                }
            }
        }
    }
    .headxl {
        font-size: 28px;
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    h3 {
        &.title40 {
            font-size: 24px;
            margin: 30px 0 10px 0;
        }
    }
    .btn-main {
        &.btn-main-xl {
            font-size: 20px;
            padding: 18px 40px;
        }
        &.btn-main-md-radius {
            font-size: 18px;
            padding: 14px 30px;
        }
    }
    .btn-secondary {
        font-size: 15px;
        padding: 10px 15px;
    }
    .iframe-parent {
        padding-top: 64px;
    }
    .overflow-cls {
        overflow: hidden;
    }
    h1 {
        &.headline {
            font-size: 34px;
            &.md-head {
                font-size: 46px;
            }
            &.lg-head {
                font-size: 60px;
            }
            &.md-head {
                font-size: 48px;
            }
        }
    }
    .grid-main-wrap {
        .size-1x1,
        .size-1x2,
        .size-2x1,
        .size-2x2 {
            .content {
                min-height: 180px;
            }
        }
        .is-css-grid {
            &.has-max-columns-5 {
                grid-auto-columns: minmax(180px, 1fr);
                grid-auto-columns: 180px;
                &.no-scroll {
                    grid-template-columns: repeat(4, 1fr);
                }
            }
            &.has-max-columns-6 {
                &.no-scroll {
                    grid-template-columns: repeat(4, 1fr);
                }
            }
            &.has-max-columns-7 {
                grid-template-columns: repeat(4, 1fr) !important;
                &.no-scroll {
                    grid-template-columns: repeat(4, 1fr);
                }
            }
            &.no-scroll {
                &.has-max-columns-4 {
                    grid-template-columns: repeat(2, 1fr);
                }
                &.has-max-columns-5 {
                    grid-template-columns: repeat(4, 1fr);
                }
                &.has-max-columns-6 {
                    grid-template-columns: repeat(4, 1fr);
                }
                &.has-max-columns-7 {
                    grid-template-columns: repeat(4, 1fr);
                }
            }
            &.category-grid {
                &.has-max-columns-5 {
                    grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
                    grid-auto-flow: column;
                    grid-auto-columns: minmax(265px, 1fr);
                    overflow-x: auto;
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                    scroll-behavior: smooth;
                    grid-template-rows: repeat(2, 100px);
                }
            }
        }
    }
    .headxl {
        font-size: 24px;
    }
    .deposit-body-main {
        .step-body-main {
            .choose-amount-main {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
    .cc-window {
        flex-direction: column !important;
        padding-bottom: 16px !important;
        &.cc-theme-edgeless {
            .cc-btn {
                padding: 0.8em 0.8em;
            }
        }
    }
    p {
        font-size: 18px;
        &.paragraph {
            font-size: 18px;
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .all-btn {
        .btn-main {
            padding: 8px;
            border-radius: 12px;
            &:hover {
                .icon {
                    color: $white-color !important;
                }
            }
            .icon {
                display: inline-block;
                width: 20px;
                color: $white-color;
            }
            .text-more {
                display: none;
            }
        }
    }
    .title-section {
        font-size: 30px;
    }
    .aboutpage {
        .accordian-list {
            .accordian-inner {
                .card {
                    h3 {
                        font-size: 22px;
                    }
                }
            }
        }
    }
    .about-body {
        .about-main {
            .number-list {
                font-size: 18px;
            }
            .content-title {
                font-size: 22px;
            }
        }
    }
    .no-data-msg {
        border-width: 2px;
        font-size: 15px;
        padding: 10px 15px;
    }
    .page-wrap {
        &.page-layout {
            margin-top: 20px;
            .bg-box {
                padding-left: 10px;
                padding-right: 10px;
            }
            ol {
                padding-left: 20px;
            }
        }
    }
    #launcher {
        bottom: 70px !important;
    }
    .section-inner {
        padding: math.div($spacing-row, 2) 0;
    }
    .game-rating {
        .stars {
            ngx-stars {
                .stars-line {
                    div {
                        .star {
                            width: 21px !important;
                            height: 21px !important;
                        }
                    }
                }
            }
        }
    }
    .btn-gray-fill {
        .icon {
            margin-right: 0;
        }
    }
    .box-bg {
        padding: 15px;
    }
    p {
        font-size: 1em;
    }
    h1 {
        &.headline {
            padding: math.div($spacing-row, 2) 0;
            font-size: 20px;
            &.lg-head {
                font-size: 30px;
            }
            &.md-head {
                font-size: 24px;
            }
            &.sm-head {
                font-size: 22px;
                margin: 0;
            }
        }
    }
    .headsm {
        font-size: 18px;
    }
    .overflow {
        overflow: hidden;
    }
    .grid-main-wrap {
        .is-css-grid {
            grid-gap: 12px;
            &.has-max-columns-7 {
                grid-template-columns: repeat(3, 1fr) !important;
            }
            &.no-scroll {
                &.has-max-columns-4 {
                    // grid-template-columns: repeat(1, 1fr);
                    margin: math.div($spacing-row, 2) 0;
                }
                &.has-max-columns-5 {
                    grid-template-columns: repeat(3, 1fr);
                }
                &.has-max-columns-6 {
                    grid-template-columns: repeat(3, 1fr);
                }
                &.has-max-columns-7 {
                    grid-template-columns: repeat(3, 1fr) !important;
                }
            }
            &.has-max-columns-5 {
                grid-template-columns: repeat(auto-fill, minmax(142px, 1fr));
                grid-auto-flow: column;
                grid-auto-columns: minmax(142px, 1fr);
                overflow-x: auto;
                -ms-overflow-style: none;
                scrollbar-width: none;
                scroll-behavior: smooth;
            }
            &.has-max-columns-6 {
                grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
                grid-auto-flow: column;
                grid-auto-columns: minmax(230px, 1fr);
                overflow-x: auto;
                -ms-overflow-style: none;
                scrollbar-width: none;
                scroll-behavior: smooth;
            }
            &.has-max-columns-7 {
                // grid-template-columns: repeat(auto-fill, minmax(193px, 1fr));
                grid-auto-flow: column;
                grid-auto-columns: minmax(193px, 1fr);
                overflow-x: auto;
                -ms-overflow-style: none;
                scrollbar-width: none;
                scroll-behavior: smooth;
                grid-auto-columns: 193px;
            }
        }
        .grid-content {
            &.is-scrollable {
                &.mobile-scroll {
                    margin-left: -15px;
                    margin-right: -15px;
                    .game-items-list {
                        .is-css-grid {
                            padding-left: 15px;
                            padding-right: 15px;
                        }
                    }
                }
                .game-items-list {
                    &.modal-gallery {
                        .gallery {
                            padding-top: math.div($spacing-row, 2);
                            padding-bottom: math.div($spacing-row, 2);
                        }
                    }
                }
            }
        }
    }
    .headxl {
        font-size: 22px;
    }
    .grid-main-wrap {
        .is-css-grid {
            &.category-grid {
                &.has-max-columns-5 {
                    margin-top: math.div($spacing-row, 2);
                    margin-bottom: math.div($spacing-row, 2);
                }
            }
        }
        .size-1x1,
        .size-1x2,
        .size-2x1,
        .size-2x2 {
            .content {
                min-height: 170px;
            }
        }
    }
}

.settings-inner {
    .headline {
        margin-left: 15px;
        label {
            margin-bottom: 0px;
            word-break: break-word;
            line-height: 1.2;
            h3 {
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .form-group {
        .form-control {
            font-size: 16px !important;
        }
    }
    .prefix-col {
        width: 160px;
    }
    #CookiebotWidget {
        bottom: 85px !important;
        right: 15px !important;
    }
    h2 {
        font-size: 22px;
        word-wrap: break-word;
        &.back-arrow-head {
            .back-header {
                width: 40px;
                padding: 13px;
            }
        }
    }
    h3 {
        font-size: 18px;
    }
    body {
        padding-top: 60px;
    }
    .game-modal-open {
        overflow: hidden;
    }
    .section-inner {
        margin-bottom: 0;
    }
    .game-theme-badge {
        padding: 4px 6px;
        font-size: 10px;
    }
    .footer {
        .wcl-section {
            .wcl-text {
                padding-top: 0px !important;
                padding-bottom: 16px;
            }
        }
    }
    .grid-main-wrap {
        .game-title {
            margin-top: 5px;
        }
        .grid-content {
            // margin-bottom: 30px;
            .game-title {
                min-height: 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 24px;
                a {
                    -webkit-line-clamp: 1;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
                .icon {
                    display: inline-block;
                    // position: absolute;
                    right: 0;
                    bottom: 0;
                    padding-left: 5px;
                    background: $body-bg-color;
                }
                a {
                    font-size: 14px;
                    line-height: 1.2;
                }
                &.game-title-v {
                    bottom: -28px;
                }
            }
            &.is-scrollable {
                &.categories {
                    padding-top: 0;
                }
            }
        }
        .is-css-grid {
            &.has-max-columns-7 {
                grid-template-columns: repeat(2, 1fr) !important;
            }
            &.no-scroll {
                &.has-max-columns-5 {
                    grid-template-columns: repeat(3, 1fr);
                }
                &.has-max-columns-6 {
                    grid-template-columns: repeat(3, 1fr);
                }
                &.has-max-columns-7 {
                    grid-template-columns: repeat(3, 1fr) !important;
                }
                &.has-max-columns-4 {
                    grid-template-columns: auto;
                }
            }
            &.has-max-columns-8,
            &.has-max-columns-5,
            &.has-max-columns-6,
            &.has-max-columns-7 {
                grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
                grid-auto-columns: minmax(165px, 1fr);
            }
            &.category-grid {
                &.has-max-columns-5 {
                    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
                    grid-auto-flow: column;
                    grid-auto-columns: minmax(320px, 1fr);
                    overflow-x: auto;
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                    scroll-behavior: smooth;
                    grid-template-rows: repeat(2, 80px);
                }
                .size-1x1 {
                    background-size: 33%;
                    padding: 30px;
                    .game-item {
                        .is-1x1 {
                            .is-1x1 {
                                width: 70px;
                                height: 70px;
                            }
                        }
                    }
                }
            }
        }
        .grid-header {
            .headline {
                span {
                    font-size: 12px;
                }
            }
        }
        p {
            font-size: 1em;
            margin-bottom: 6px;
        }
        .game-info.with-content {
            padding-right: 8px !important;
            padding-left: 8px !important;
        }
        .grid-content.is-scrollable {
            .game-items-list {
                min-width: 100%;
                max-width: 100%;
            }
        }
        .game-custom-grid {
            .size-1x1,
            .size-1x2,
            .size-2x1,
            .size-2x2 {
                min-height: 110px;
                height: auto;
            }
            .size-1x1,
            .size-1x2 {
                max-width: 165px;
            }
        }
        .categories {
            .show-all-cat {
                .image {
                    width: 255px;
                    height: 100px;
                }
            }
            .cat-title {
                font-size: 14px;
                line-height: 1.3;
            }
        }
        .game-item-btn {
            .item-btn-inn {
                font-size: 13px;
            }
        }
    }
    .group-input {
        input {
            border-bottom: 0 !important;
        }
    }
    .row-custom {
        display: block;
    }
}

@media (max-width: 478.98px) {
    .grid-main-wrap {
        .image {
            &::after {
                border-top-left-radius: $border-radius;
                border-top-right-radius: $border-radius;
            }
            img {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
        .game {
            height: inherit !important;
            &.is-1x1,
            &.is-2x2 {
                .game-info {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
            }
        }
        .game-item-btn {
            position: inherit;
        }
        .game.has-hover,
        .show-on-hover {
            overflow: visible;
        }
        .grid-content {
            .size-1x1 {
                .content {
                    height: auto !important;
                    min-height: inherit !important;
                    max-height: inherit !important;
                }
            }
        }
        .is-css-grid {
            &.has-max-columns-5 {
                grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
                grid-auto-columns: minmax(120px, 1fr);
            }
            &.has-max-columns-7 {
                grid-template-columns: repeat(2, 1fr) !important;
            }
        }
        .size-1x1,
        .size-1x2,
        .size-2x1,
        .size-2x2 {
            .content {
                min-height: 120px;
            }
        }
        .has-hover {
            &:hover {
                .show-on-hover {
                    padding: 0;
                    .btn-main {
                        font-size: 12px;
                        line-height: 1;
                        padding: 5px;
                        border-radius: 8px;
                    }
                }
            }
        }
        .game-info {
            a {
                &.game-demo-link {
                    font-size: 14px;
                }
            }
        }
    }
}

@media (max-width: 385px) {
    .grid-main-wrap {
        .is-css-grid {
            &.no-scroll {
                &.has-max-columns-5 {
                    grid-template-columns: repeat(2, 1fr);
                }
                &.has-max-columns-6 {
                    grid-template-columns: repeat(2, 1fr);
                }
                &.has-max-columns-7 {
                    grid-template-columns: repeat(2, 1fr) !important;
                }
            }
            &.has-max-columns-7 {
                grid-template-columns: repeat(2, 1fr) !important;
            }
        }
        a.game-item-link {
            display: none;
        }
    }
}